
define('alertsView',['require','jquery','underscore','backbone','commonutils','GoogleAnalyticsUtils','firebaseApp','queueUtil','jUtil','ajaxUtil','hbs!tpl/globalAlerts/alertCounterVersion1','hbs!tpl/globalAlerts/alertCounterVersion1NewSkin','hbs!tpl/globalAlerts/whatsNewFeature','hbs!tpl/globalAlerts/addWallet','hbs!tpl/globalAlerts/whatsNewFeatureModal','hbs!tpl/globalAlerts/fraudulentErrorModal','hbs!tpl/globalAlerts/alertTuple','hbs!tpl/globalAlerts/alertTupleNewSkin','hbs!tpl/globalAlerts/saveSearchTuple','hbs!tpl/globalAlerts/saveSearchTupleNewSkin','hbs!tpl/globalAlerts/cifReminderTuple','hbs!tpl/globalAlerts/successTuple','hbs!tpl/globalAlerts/defaultUserAlerts','loginRegisterView','newRegistrationView','userProfileView','loginView','globalAlertsIdologyView','bidModel','bidSignInView','placeBidView','bidWizardModel','saveListDrawerView','monitoringView','validator','mobileVerificationView','proxyBiddingView'],function (require) {
    'use strict';

    var $ = require("jquery"),
        _ = require("underscore"),
        Backbone = require("backbone"),
        
        Utils = require("commonutils"),
        GAUtils = require("GoogleAnalyticsUtils"),
        Firebase = require("firebaseApp"),
        queueUtil = require("queueUtil"),
        jUtil = require("jUtil"),
        ajaxUtil = require("ajaxUtil"),
        
        alertCounterVersion1Template = require("hbs!tpl/globalAlerts/alertCounterVersion1"),
        alertCounterVersion1TemplateNewSkin = require("hbs!tpl/globalAlerts/alertCounterVersion1NewSkin"),
        whatsNewTemplate = require("hbs!tpl/globalAlerts/whatsNewFeature"), 
        addWalletTemplate = require("hbs!tpl/globalAlerts/addWallet"),
        whatsNewModalTemplate = require("hbs!tpl/globalAlerts/whatsNewFeatureModal"), 
        fraudulentErrorModalTemplate = require("hbs!tpl/globalAlerts/fraudulentErrorModal"), 
        alertTupleTemplate = require("hbs!tpl/globalAlerts/alertTuple"),
        alertTupleTemplateNewSkin = require("hbs!tpl/globalAlerts/alertTupleNewSkin"),
        saveSearchTupleTemplate = require("hbs!tpl/globalAlerts/saveSearchTuple"),
        saveSearchTupleTemplateNewSkin = require("hbs!tpl/globalAlerts/saveSearchTupleNewSkin"),
        cifReminderTupleTemplate = require("hbs!tpl/globalAlerts/cifReminderTuple"),
        bidPlacedSuccessTemplate = require("hbs!tpl/globalAlerts/successTuple"),
        defaultUserAlertsTempalte = require("hbs!tpl/globalAlerts/defaultUserAlerts"),
        loginRegisterView = require('loginRegisterView'),
        newRegistrationView = require('newRegistrationView'),
        userProfileView =  require("userProfileView"),
        loginView = require('loginView'),
        globalAlertsIdologyView = require("globalAlertsIdologyView"),
        BidModel = require("bidModel"),
        BidSignInView = require("bidSignInView"),
        PlaceBidView = require("placeBidView"),
        placeBidView =  new PlaceBidView(),
        bidWizardModel = require("bidWizardModel"),
        SaveListDrawerView = require("saveListDrawerView"),
        saveListDrawerView = new SaveListDrawerView(),
        monitoringView = require("monitoringView"),
        validator = require("validator"),
        globalAlertsIdologyViewFunc = new globalAlertsIdologyView(),
        MobileVerificationView = require("mobileVerificationView"),
        proxyBidView = require("proxyBiddingView");
    var AlertsView = Backbone.View.extend({
        firebaseApp: null,
        isBiddingInProgress: false,
        isBidValidationInProgress: false,
        userId: null,
        messageId: null,
        tenantId: '',
        isSignedOut: false,
        listingNodeUrls: [],
        containerMaxHeight: 435,
        timerObjects: [],
        autoBidGlobalAlertsEnabled: 'N',
        el: $(".top-header-navigation"),
        events: {
            "click .globalAlertsIcon, .alertCount": "showAlertContainer",
            "click .bid-minus": "calculateBidReduction",
            "click .bid-plus": "calculateBidIncrement",
            "click .ga-view-details-link": "showMorePropertyDetails",
            "click .login-view-details": "toggleDefaultContentForLogin",
            "click .close-details .hide-view-container": "hideViewDetails",
            "click .ga-place-bid": "placeBid",
            "click .ga-confirm-bid": "confirmBid",
            "click .ga-login-button": "showLoginPopup",
            "click .ga-register-button": "showRegisterPopup",
            "click .ga-idology-button": "setIdologyFlag",
            "click .ga-profile-button": "updateProfileButton",
            "click .close-confirm-details": "hideConfirmDetails",
            "click .ga-edit-bid-details": "goToBidWizard",
            "click .remove-success-pane": "removeSuccessTuple",
            "click .save-seach-link": "openSaveSearchLink",
            "click .gobal-alerts-overlay, .globalAlertsSvgIcon, .gobal-alerts-drawer-container .close-icon, .gobal-alerts-drawer-container .drawer-saved-cancel,.gobal-alerts-drawer-container .drawer-cancel": "showAlertContainer",
            "change .tcpaConsent" : "displayTcpaConsent",
            "click #saveTcpaConsent" : "saveTcpaConsent",
            "blur .tcpaPhone" : "validatePhone",
            "keypress .tcpaPhone" : "validatePhoneInput",
            "click .ga-cif-reminder-tuple .cif-actions": "logCIFlinkAction",
            "click .whats-new-carousel .wn-action": "showFeatureDetails",
            "click .whats-new-carousel .carousel-control": "logUserActions",
            "click .auto-bid-section-global-alerts .enable-auto-bid" : "checkEnableAutoBid",
            "blur .auto-bid-section-global-alerts .b_autobid_amount" : "checkAutoBidAmount",
            "keypress .auto-bid-section-global-alerts .amountField" : "checkBidKeyPress",
            "click .whats-new-carousel .indicators": "logUserActions",
            "click .addFundGA" : "walletOpen"
            
        },

        initialize: function () {
            try {
                this.isMobile = $(window).width() < 768 ? true : false;
                this.visitorId = $.cookie('visitorId');
                this.smsAlert = false;
                this.userDetails = this.setUserDetails();
                this.stateList = [];
                this.marketingAlerts = [];
                this.referrer = null;
                this.alert_timer = '';
                this.backupBidAlert = false;
                this.backupBidExpiryAlert = false;
                this.propsBackupEnabled = false;
                // this.fromOutBid = false;
                window.mobileVerificationView = MobileVerificationView;
                window.proxyBidView = proxyBidView;
                this.outBidData = {
                    listingId : '',
                    fromOutBid : false
                };//                window.phoneVerificationView = PhoneVerification;

                this.pageName = GAUtils.eventTracking.recognizePageType();
                // adding window resize event, which will re-render alerts view according to view
                // this.addWindowResizer();
                
                this.tenantId = $(".top-header-navigation").attr('data-app-tnt') || '';
                var status = $(".top-header-navigation").attr('data-status') || null;
                if ( this.tenantId && status === "encoded" ) {
                    this.tenantId = window.atob(this.tenantId);
                }
                this.buyersPremiumRequest = null;
                this.attachEvents();
                this.showAutoBid = false;
                this.bidSubmitterRole = '';
                this.submitFromAlertView = false;
                $(document).on('change', '.globalAlertTuples .h-select-box', function(){
                    $(this).parents('.ga-tuple-item').find('.backupBidDayError').slideUp();
                });

                if(!this.firebaseApp){
                    this.initFirebase();
                }
                this.initProxybidFB();

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:initialize|" + e, null, 1);
            }
        },

        logCIFlinkAction: function(e) {
            GAUtils.eventTracking.trackevent("Global Alerts", "Global Alerts - CIF link clicked",  this.userDetails + "| pageName:"+this.pageName);
        },
        setUserDetails: function() {
            var userSession = window.userSession || {},
                userId = userSession.userId || null,
                userStatus = userSession.userStatus || null;
            return " UID: "+ userId + " | userStatus: " + userStatus;
        },

        /**
         * Function to display TCAP container after the selection of sms opt in 
         *     Params : null
         *          
         */
        displayTcpaConsent: function(){
            $(".tcpaFormContainer").removeClass("hide");
            if( $(".tcpaConsent").val() != null && $("#tcpaConsent").val() == 'YES') {
                $('#opt-sms-container').find('.tcpaConsentContent').removeClass('hide');
                $('#opt-sms-container').find('#tcpaPhone').attr('disabled', false);
            } else {
                var userSession = window.userSession || {},
                userPhpne = userSession.phone || null;
                $(".tcpaPhone").val(userPhpne);
                $('#opt-sms-container').find('.tcpaConsentContent').addClass('hide');
                $('#opt-sms-container').find('#tcpaPhone').attr('disabled', true);
            }
        },
        
        /**
         * Function Save user selection for SMS opt in /out 
         *      Params : userPhone number 
         *          
         */

        saveTcpaConsent:  function(){
             
            var self = this,
                smsOptedFlag = $(".tcpaConsent").val() || null,
                element = $(".tcpaPhone"),
                url = (smsOptedFlag == 'YES')? "/portal/lean-regn/saveUserSmsOptInFlag" : "/portal/lean-regn/saveUserSmsOptOutFlag";
           
            $(element).blur();
          
            if(element.hasClass("error") ) {
                return false;
            } 
           var phone = element.val() || null;

            if(smsOptedFlag && phone != null) {
                jQuery.httpRequest({
                    url: contextPath + url,
                    type: 'POST',
                    data: JSON.stringify({ 'phoneNumber' : phone }),
                    contentType:'application/json',
                    success: function (responseData) {
                        if(responseData && responseData.status == 'SUCCESS') {
                            $("#opt-sms-container").hide();
                            
                            if(smsOptedFlag == 'YES') {
                                GAUtils.eventTracking.trackevent("Global Alerts", "Opt in For SMS", self.userDetails + " | " + smsOptedFlag+ "| PageName:"+self.pageName);
                                $(".opt-in-success .success").removeClass("hide");
                                $(".opt-in-success .error").addClass("hide");
                            } else {
                                $(".opt-in-success .error").removeClass("hide");
                                $(".opt-in-success .success").addClass("hide");
                                GAUtils.eventTracking.trackevent("Global Alerts", "Opt Out For SMS", self.userDetails + " | " + smsOptedFlag + "| PageName:"+self.pageName);
                            }
                            $(".opt-in-success").removeClass("hide");
                            self.smsAlert = false;
                        }
                        
                    },
                    error: function (error) {
                        GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:saveTcpaConsent|" + error, null, 1);
                    }
                });
            }
        },
        // to allow only numberic input
        validatePhoneInput : function(event){
            try
            {
              var obj = '#' + event.target.id;
              if(validator.phoneField(event , obj)){
                    return true;
                }else{
                    return false;
                }
              }
            catch(e)
             {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:validatePhoneInput|" + e,null,1);
             }
          },
        
          // to validate the phone number field
        validatePhone :function(event){
            try
            {
              var obj = '#' + event.target.id,   
                  value = $.trim($(obj).val()), 
                  status;
                  
                  if(value === ''){
                    $(obj).addClass('error');  
                  }else if(!(validator.phone($.trim($(obj).val()) , obj))){
                    $(obj).addClass('error');
                  }else{
                    $(obj).removeClass('error');
                  }
            }
            catch(e)
             {
                GAUtils.eventTracking.trackevent("Error","GlobalAlerts", "file:alertsView.js|function:validatePhone|"+ e,null,1);
             }
          },

        initFirebase: function () {
            try {

                var apiKey = $(".top-header-navigation").attr('data-app-identifier') || null,
                    authDomain = $(".top-header-navigation").attr('data-app-domain') || null, 
                    databaseUrl = $(".top-header-navigation").attr('data-app-url') || null,
                    projectId = $(".top-header-navigation").attr('data-app-name') || null,
                    status = $(".top-header-navigation").attr('data-status') || null;

                if (status === "encoded") {
                    apiKey = apiKey ? window.atob(apiKey) : null;
                    authDomain = authDomain ? window.atob(authDomain) : null;
                    databaseUrl = databaseUrl ? window.atob(databaseUrl) : null;
                    projectId = projectId ? window.atob(projectId) : null;
                }

                if(apiKey && authDomain && databaseUrl && projectId) {

                    // Initialize App
                    this.firebaseApp = Firebase.init({
                        apiKey: apiKey,
                        authDomain: databaseUrl,
                        databaseURL: databaseUrl,
                        projectId: projectId
                    });
                } else {
                    GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:initFirebase|Firebase configuration details not available", null, 1);
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:initFirebase|" + e, null, 1);
            }
        },

        authenticate: function (options) {

            try {

                options = options || {};

                var self = options.viewObject || this,
                    callback = options.callback || '';

                self.getAuthToken('', function (error, token) {

                    if (error) {
                        return;
                    }

                    options.token = token;

                    Firebase.authenticate(options, function (error, user) {
                        if (error) {
                            // Fire base authentication error
                            GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "Firebase authentication failure - userId: " + self.userId + " | token: " + token + error, null, 1);
                        } else {
                            //GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "Firebase authentication success - ", "userId: " + self.userId + " | token: " + token);
                            if (callback) {
                                callback(options);
                            }
                        }
                    });
                });
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:authenticate|" + e, null, 1);
            }
        },
        getAuthToken: function (options, callback) {
            // Get authentication token

            try {
                var self = this;
                jQuery.httpRequest({
                    url: contextPath + '/portal/getAlertSummary',
                    type: 'GET',
                    success: function (responseData) {
                        var response = responseData || {},
                            status = response.status || 'ERROR',
                            token = response.data && response.data.token || '';

                        if (status === 'SUCCESS' && token) {
                            callback(null, token);
                        } else {
                            GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "Hubzu getAuthToken error - userId: " + self.userId + " | response.status: " + status + response, null, 1);
                            callback(response.errorMessage || 'Error while getting token');
                        }
                    },
                    error: function (error) {
                        GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:getAuthToken|" + error, null, 1);
                        callback(error);
                    }
                });
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:getAuthToken|" + e, null, 1);
            }

        },

        initProxybidFB : function(){
            var self = this;
            // var alertView = window.globalAlertView || null;
            /* Docu Sign Firebase URL Config */
            var loginStatus = globalParam.config.isLoggedin || false;

            if(loginStatus && (pageName == "buyerDashboard" || pageName == "pdp")){
                var userID = (typeof window.userSession !== 'undefined') ? (window.userSession && window.userSession.userId || '' ): '';
                var docuSignDatabaseUrl = 'userDetails/' + $.cookie('userId') + "/userDetailMessage";
                if(self.tenantId) { 
                    docuSignDatabaseUrl = self.tenantId + "/" + docuSignDatabaseUrl;
                }
                
                var listingNodeReference = self.firebaseApp.database().ref(docuSignDatabaseUrl);
                listingNodeReference.on('value', function (snapshot) {
                    if(snapshot !=null && snapshot.val()!=null && snapshot.val().fcDocuSignFlag && snapshot.val().fcDocuSignFlag =="Y"){
                        $(".docuSignNotVerified").addClass("hide");
                        $(".docuSignVerified").removeClass("hide");
                    }else{
                        $(".docuSignNotVerified").removeClass("hide");
                        $(".docuSignVerified").addClass("hide");
                    }
                }, function (error) {
                      self.notificationError({
                        error: error,
                        databaseUrl: docuSignDatabaseUrl,
                        event: 'value'
                      });
                });
            }
        },

        listenStateChage: function (options) {

            try {
                options = options || {};
                var self = this,
                    userSession = window.userSession || {},
                    userId = userSession.userId || null,
                    userStatus = userSession.userStatus || null,
                    userPhpne = userSession.phone || null,
                    optInEnabled = userSession.optInEnabled || false,
                    smsOptedFlag = userSession.smsOptedFlag || null,
                    userIdologyStatus = userSession.idologyStatus || false;

                self.userId = options.userId || null;
                self.smsAlert = false;

                // Verify user is idology verified or not.
                if (userStatus === 'ACTIVE') {
                    userStatus = userIdologyStatus ? userStatus : 'IDOLOGY';
                        if( optInEnabled && smsOptedFlag === null){
                        //if( smsOptedFlag === null){
                            self.smsAlert = true;
                            $(".opt-sms-container").removeClass("hide");
                            $(".tcpaPhone").val(userPhpne);
                            $('.globalAlertsContainer .alertCount, .user-alerts-count').removeClass('hide');
                            $('.globalAlertsContainer .alertCount, .user-alerts-count').html('1');
                        }
                }

                // If user is active, clear default notification
                if (userStatus === 'ACTIVE' && self.userId) {

                    if (!self.sessionRequiredForValidation) {
                        self.clearNotification();
                        self.displayAlertsView({ view: 'loader' });    
                    }

                } else {
                    // Don't attach listeners if user is not Active
                    self.showDefaultNotification({
                        userId: self.userId,
                        userStatus: userStatus
                    });
                    return;
                }

                if (!self.firebaseApp) {
                    self.initFirebase();
                }
                
               // GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "listenStateChage - initializing authState listner", "");

                // Attach user state(Firebase) change event 
                var unsubscribeStateListener = self.firebaseApp.auth().onAuthStateChanged(function (user) {
                    if (user) {

                        // Initialize notification counter
                        self.initNotificationCounter({
                            viewObject: self,
                            userId: self.userId
                        });

                        // Initialize message listner
                        self.initMessageListner({
                            viewObject: self,
                            userId: self.userId
                        });

                    } else {
                        /* 
                            - User is not available.
                            - Since we are initiating listenStateChage event on  hubzu user signin, 
                                and firebase user is not available, we are authenticating firebase. 
                        */
                        if (self.isSignedOut) {
                            // User signed out - detach authStateChange observer
                            unsubscribeStateListener();
                            self.clearNotificationListners({
                                viewObject: self
                            });
                        } else {
                            self.authenticate({});
                        }

                        self.isSignedOut = false;

                    }
                });


            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:listenStateChage|options: " + JSON.stringify(options) || '' + e, null, 1);
            }

        },
        clearNotificationListners: function (options) {

            options = options || {};

            var self = options.viewObject || this,
                messageActivityNode = 'users/' + self.userId + '/messages',
                messageSummeryNode = 'users/' + self.userId + '/summary';

            if(this.tenantId) { 
                messageActivityNode = this.tenantId + "/" + messageActivityNode;
                messageSummeryNode = this.tenantId + "/" + messageSummeryNode;
            }
            
            // detach firebase listeners
            self.listingNodeUrls.push(messageSummeryNode);
            self.listingNodeUrls.push(messageActivityNode);
            self.listingNodeUrls.push('/.info/serverTimeOffset');
            self.detachListners({
                databaseUrls: self.listingNodeUrls
            });

            self.showDefaultNotification({});
        },
        /*
            - Default notification for non logged in users
        */
        showDefaultNotification: function (options) {
            try {

                options = options || {};
                var self = this,
                    userStatus = options.userStatus || 'LOGIN',
                    alertContainer = ".gaTuple",
                    userData = {},
                    isDefaultAlertsEnabled = true;
                    

                userData.userStatus = userStatus;
                userData.contextPath = contextPath || '';
                userData.componentsCmsNode = saveListDrawerContent;
                userData.isDefaultAlertsEnabled = isDefaultAlertsEnabled;
                
                if(!self.smsAlert) {
                    $(".opt-sms-container, .opt-in-success").addClass("hide");
                }

                // GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "Showing default alert notification ", "userId: " + self.userId + " | User status: " + userStatus);
                if (userStatus !== 'ACTIVE') {
                    $('.globalAlertsContainer .alertCount, .user-alerts-count').removeClass('hide');
                    $('.globalAlertsContainer .alertCount, .user-alerts-count').html('1');
                      
                    //Load the main container to load the corresponding view for different user status
                    $(self.el).find(alertContainer).html(defaultUserAlertsTempalte(userData));
                        
                    self.displayAlertsView({
                        view: 'gaTuple'
                    });

                    if(isDefaultAlertsEnabled){                        
                        self.renderNewProfile({'userStatus':userStatus});
                    }

                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:showDefaultNotification| options: " + JSON.stringify(options) || '' + e, null, 1);
            }
        },

        /**
         * Function: renderNewProfile(); - Loads the corresponding user templates depends on the input user status (LOGIN/REGISTER | PROFILE | IDOLOGY)
         * @param {*} profileParam 
         */
        renderNewProfile : function(profileParam){
            var options = {};
            var profileView = new userProfileView();
            var callbackOptions = {
                openPhoneVerification: true,
                callBackFunc : this.renderNewProfile
            }
            
            switch (profileParam.userStatus){
                case 'LOGIN':
                    var globalAlertsCmsNode = typeof saveListDrawerContent !== 'undefined' && saveListDrawerContent.globalAlerts.labels || {};
                    newRegistrationView.render('globalRegisrationContainer');
                    newRegistrationView.$el = this.$('#globalRegisrationContainer');
                    newRegistrationView.delegateEvents();
                    // Initiate Login view events
                    loginView.$el = this.$('.ga-user-login-container');
                    loginView.parentView = this;
                    loginView.render({container: '.ga-user-login-container'});
                    loginView.createCaptch();
                    loginView.delegateEvents();
                    if(typeof golbalPDPView !== "undefined" && golbalPDPView.showAlertsContainer) {
                        $(".ga-register-container").addClass("active");
                        $(".ga-login-container").removeClass("active");
                        $(".title-container .title-text, .mobile-title .title-txt").text(globalAlertsCmsNode.lockboxAccessCodeRegister);
                    }
                    break;
                case 'PROFILE':
                    
                    options.container = 'globalProfileContainer';
                    profileView.render(options);    
                    profileView.$el = this.$('#globalProfileContainer');
                    profileView.parentView = this;
                    profileView.delegateEvents();
                    
                    break;
                case 'IDOLOGY':
                    globalAlertsIdologyViewFunc.render({'renderView':'globalAlertsIdology'});
                    break;

            }
        },
        toggleDefaultContentForLogin: function (e) {

            try {

                var self = this,
                    tupleContainer = self.isMobile ? $('.GACMobile') : $('.GACDesktop'),
                    contentDivObject = $(tupleContainer).find(".defaultAlertContainer .view-details-expand");

                    this.userDetails = this.setUserDetails();
                    
                if (contentDivObject.is(':hidden')) {
                    GAUtils.eventTracking.trackevent("Global Alerts", "Global Alerts - view more details clicked", this.userDetails +" | visitorId: "+this.visitorId +  "| PageName:"+self.pageName);
                    $(tupleContainer).find(".view-details-expand").slideDown("slow");
                } else {
                    GAUtils.eventTracking.trackevent("Global Alerts", "Global Alerts - hide more details clicked",  this.userDetails + " | visitorId: " +this.visitorId +  "| PageName:"+self.pageName);
                    $(tupleContainer).find(".view-details-expand").slideUp();
                }
            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:toggleDefaultContentForLogin|" + exception, null, 1);
            }
        },
        showLoginPopup: function (e) {
            var self = this;
            this.userDetails = this.setUserDetails();
            GAUtils.eventTracking.trackevent("Global Alerts", "Global Alerts - Login button clicked",  this.userDetails + " | visitorId: " +this.visitorId +  "| PageName:"+self.pageName);
            $("#ga-loader-mask").fadeOut(400);
            loginRegisterView.funnelStart = true; 
            loginRegisterView.openLoginPopup({});

            // Hider new alerts drawer
            this.showAlertsDrawer();
            
            $('.tuple-container').slideUp(function () {
                $('.globalAlertTuples').removeClass('active');
            });
            
        },
        showRegisterPopup: function (e) {
            this.userDetails = this.setUserDetails();
            GAUtils.eventTracking.trackevent("Global Alerts", "Global Alerts - Register button clicked",  this.userDetails + " | visitorId: " +this.visitorId +  "| PageName:"+this.pageName);
            $("#ga-loader-mask").fadeOut(400);
            loginRegisterView.funnelStart = true;
            loginRegisterView.openLoginPopup({
                openRegister: 'Y'
            });

            // Hider new alerts drawer
            this.showAlertsDrawer();
            
            $('.tuple-container').slideUp(function () {
                $('.globalAlertTuples').removeClass('active');
            });
        },
        setIdologyFlag: function (e) {
            this.userDetails = this.setUserDetails();
            localStorage.setItem("funnelStart", true);
            GAUtils.eventTracking.trackevent("Global Alerts", "Glboal Alerts - Idology button clicked",  this.userDetails + " | visitorId: "+this.visitorId +  "| PageName:"+this.pageName);
            this.hideAlertContainer();
            
            // Hider new alerts drawer
            this.showAlertsDrawer();

            if (Backbone.history.getFragment() === 'myProfile') {
                e.preventDefault();
                $("#verifyIdology").trigger("click");
            } else {
                localStorage.setItem("showIdologyPopup", "Y");
            }
        },
        updateProfileButton: function (e) {
            localStorage.setItem("funnelStart", true);
            this.userDetails = this.setUserDetails();
            
            // Hider new alerts drawer
            this.showAlertsDrawer();

            this.hideAlertContainer();
            GAUtils.eventTracking.trackevent("Global Alerts", "Global Alerts - Update profile button click", this.userDetails + " | visitorId: "+this.visitorId +  "| PageName:"+this.pageName);
            $('body').removeClass('modal-open');
        },

        /*
            - initiate listner on notificaion counter
        */
        initNotificationCounter: function (options) {

            try {

                // GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "Initializing Notification counter on Summary node", "");
                options = options || {};
                var self = options.viewObject || this,
                    userId = options.userId || null,
                    databaseUrl,
                    summaryNodeReference,
                    initState = true,
                    displayTime = saveListDrawerContent.globalAlerts.hubzuConfig.displayTime || 5000,
                    previousSnapshot = 'init',
                    unReadMessageCount, alertMessage;


                if (!userId) {
                    // GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "function:initNotificationCounter - User id is null in while initializing alert engine", "");
                    return;
                }

                self.userId = userId;

                if (self.firebaseApp.auth().currentUser) {

                    // GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "Initialising Notification counter - Firebase session is available", "userId: " + self.userId);

                    databaseUrl = 'users/' + userId + '/summary';
                    if(this.tenantId) { 
                        databaseUrl = this.tenantId + "/" + databaseUrl;
                    }
                    summaryNodeReference = self.firebaseApp.database().ref(databaseUrl);
                    summaryNodeReference.on('value', function (snapshot) {

                        // GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "initNotificationCounter: Listing got updated");

                        if ( previousSnapshot === 'init' ) {

                            unReadMessageCount = snapshot.child('pendingMessages').val() || 0;
                            alertMessage = snapshot.child('lastMessage').val() || '';

                            $.queueItem({
                                displayTime: displayTime,
                                viewObject: self,
                                unReadMessageCount: unReadMessageCount,
                                alertMessage: alertMessage,
                                initState: initState,
                                snapshot: snapshot,
                                callback: self.showNotification
                            });

                        } else {

                            if((previousSnapshot && previousSnapshot.lastModifiedBy || '') !== (snapshot && snapshot.val() && snapshot.val().lastModifiedBy || '') ||
                                (previousSnapshot && previousSnapshot.lastMessageType || '') !== (snapshot && snapshot.val() && snapshot.val().lastMessageType || '') ) {

                                unReadMessageCount = snapshot.child('pendingMessages').val() || 0;
                                alertMessage = snapshot.child('lastMessage').val() || '';

                                $.queueItem({
                                    displayTime: displayTime,
                                    viewObject: self,
                                    unReadMessageCount: unReadMessageCount,
                                    alertMessage: alertMessage,
                                    initState: initState,
                                    snapshot: snapshot,
                                    callback: self.showNotification
                                });
                            }   
                        }

                        previousSnapshot = snapshot.val();                        
                        initState = false;

                    }, function (error) {
                        //GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "initNotificationCounter: Listing got updated");
                        self.notificationError({
                            error: error,
                            viewObject: self,
                            databaseUrl: databaseUrl,
                            event: 'value'
                        });
                    });

                } else {
                   // GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "Initialising Notification counter - Firebase session is not available", "userId: " + self.userId);
                    options.viewObject = self;
                    options.callback = self.initNotificationCounter;
                    self.authenticate(options);
                }

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:initNotificationCounter|" + e, null, 1);
            }

        },
        /*
            - initiate listner on notificaion counter
        */
        initMessageListner: function (options) {

            try {
                options = options || {};
                var self = options.viewObject || this,
                    messageNodeReference,
                    databaseUrl,
                    messagesList = [],
                    config;

                if (self.firebaseApp.auth().currentUser) {

                    // GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "Initializing listener on Messages node - Firebase session is available", "userId: " + self.userId);

                    databaseUrl = 'users/' + self.userId + '/messages';
                    if(this.tenantId) { 
                        databaseUrl = this.tenantId + "/" + databaseUrl;
                    }
                    messageNodeReference = self.firebaseApp.database().ref(databaseUrl);

                    messageNodeReference.once('value', function(snapshot) {

                        // get the list of existing messages and skip 'child_added' event for the first time for this messages
                        snapshot.forEach(function(child) {
                            messagesList.push({
                                id: child.key,
                            });
                        });

                        messageNodeReference.on('child_changed', function (snapshot) {

                            // GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "Message node listner: child_changed - userId: " + self.userId, snapshot.val());
                            self.updateMessage({
                                snapshot: snapshot,
                                viewObject: self
                            });
    
                        }, function (error) {
                            self.notificationError({
                                error: error,
                                viewObject: self,
                                databaseUrl: databaseUrl,
                                event: 'child_changed'
                            });
                        });
    
                        messageNodeReference.on('child_added', function (snapshot) {
                        
                            config = {
                                key: 'id',
                                value: snapshot.val().id,
                                list: messagesList
                            };
                            
                            if(!$.isObjectExists(config)){
                                self.addNewTuple({
                                    viewObject: self,
                                    snapshot: snapshot
                                });
    
                                messagesList.push({
                                    id: snapshot.val().id
                                });
                            } 
                        }, function (error) {
                            self.notificationError({
                                error: error,
                                viewObject: self,
                                databaseUrl: databaseUrl,
                                event: 'child_added'
                            });
                        });
    
                        messageNodeReference.on('child_removed', function (snapshot) {
    
                            // GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "Message node listner: child_removed - userId: " + self.userId, snapshot.val());
                            self.removeListing({
                                viewObject: self,
                                messageId: snapshot.val().id,
                                messageType: snapshot.val().type
                            });
    
                            messagesList = $.removeObjectWithKey({key: 'id', value: snapshot.val().id, list: messagesList});
                            console.log(" messagesList after deletion : ", messagesList);
    
                        }, function (error) {
                            self.notificationError({
                                error: error,
                                viewObject: self,
                                databaseUrl: databaseUrl,
                                event: 'child_removed'
                            });
                        });
                    });


                } else {
                    //  GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "Initializing listener on Messages node - Firebase session is not available", "userId: " + self.userId);
                    options.callback = self.initMessageListner;
                    options.viewObject = self;
                    options.userId = self.userId;
                    self.authenticate(options);
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:initMessageListner|" + e, null, 1);
            }
        },

        /**
        *   Checks whether alert is related to bid or not based on type of alert
        *   @params {Object} 
        *                  - type (String) : message type 
        */
        isBidAlert: function(options) {

            options = options || {};

            var index,
                messageType = options.type || null,
                bidAlertTypes = ['OUTBID', 'NEW_BID', 'AUCTION_EXTENDED', 'BACKUPBID', 'BACKUP_BID_EXPIRY'];

            index = messageType ? bidAlertTypes.indexOf(messageType) : -1; 

            return index >= 0 ? true : false;

        },

        updateMessage: function (options) {
            try {
                options = options || {};
                var snapshot = options.snapshot && options.snapshot.val() || {},
                    messageId = snapshot.id || null,
                    messageText = snapshot.message || '',
                    messageStatus = snapshot.status || '',
                    messageType = snapshot.type || null,
                    daysCount = snapshot.backupBidExpiryDays || '',
                    self = options.viewObject || this,
                    mainContainer = self.isMobile ? $('.GACMobile .tuple-container') : $('.GACDesktop .tuple-container');
                                    
                if (self.isBidAlert({type: messageType}) && messageId && messageText) {
                    if(messageType == "BACKUP_BID_EXPIRY"){
                        messageText = messageText.replace("###DAYS_COUNT###", daysCount);
                    }
                    mainContainer.find('.' + messageId + ' .ga-alert').text(messageText);

                    if(messageStatus && messageStatus === 'expired') {

                        self.hideConfirmDetailsView({
                            messageId: messageId,
                            viewObject: self,
                            callback: function(){
                                messageText = saveListDrawerContent.globalAlerts.labels.auctionEndedLabel || 'Auction Ended';
                                mainContainer.find('.' + messageId + ' .ga-bid-amount').hide();
                                mainContainer.find('.' + messageId + ' .ga-cta').hide();
                                mainContainer.find('.' + messageId + ' .ga-ownitnow-container').hide();
                                mainContainer.find('.' + messageId + ' .ga-alert').text(messageText);
                                mainContainer.find('.' + messageId).addClass('expired-alert');
                            }
                        });
                    }
                }

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:updateMessage|" + e, null, 1);
            }
        },

        addNewTuple: function (options) {

            try {
                options = options || {};
                var snapshot = options.snapshot && options.snapshot.val() || {},
                    messageId = snapshot.id || '',
                    messageType = snapshot.type || null,
                    self = options.viewObject || this;

                if ( (self.isBidAlert({type: messageType}) || messageType === 'CIF_REMINDER') && messageId) {

                    if (jQuery.hasPendingRequest()) {
                        return;
                    }

                    jQuery.httpRequest({
                        url: contextPath + '/portal/getAlert/' + messageId,
                        type: 'GET',
                        success: function (responseData) {
                            var response = responseData || {},
                                status = response.status || 'ERROR',
                                alerts = response.alerts || [];

                            if (status === 'SUCCESS' && alerts.length) {
                                self.showAlerts(null, {
                                    self: self,
                                    alerts: alerts,
                                    isNewTuple: true
                                });
                            } else {
                                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "Error while getting alert details with message ID|userId: " + self.userId + " | messageId:" + messageId, null, 1);
                            }
                        },
                        error: function (error) {
                            GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:addNewTuple|" + error, null, 1);
                        }
                    });
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:addNewTuple|" + e, null, 1);
            }
        },

        removeSuccessTuple: function(e) {
            try{
                var self = this,
                    messageId = $(e.target).attr('data-message') || null,
                    reOpenTuple = $(e.target).attr('data-open-tuple') || 'N',
                    mainContainer = self.isMobile ? $('.GACMobile .tuple-container') : $('.GACDesktop .tuple-container');
                GAUtils.eventTracking.trackevent("Global Alerts", "Global Alerts - close bid placed success tuple icon clicked", this.userDetails + " | visitorId: "+this.visitorId +  "| PageName:"+this.pageName);
                
                $(".bid-success-container").slideUp();

                if(messageId && reOpenTuple === 'Y') {
                    $('.bemail-' + messageId).show();
                    $('.' + messageId).slideDown();
                } else {
                    if(mainContainer.find('.gaTuple').outerHeight() < 10){
                        self.hideAlertContainer();
                    }
                }

            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:removeSuccessTuple|" + exception, null, 1);
            }
        },

        removeListing: function (options) {
            try {
                options = options || {};
                var messageId = options.messageId || '', 
                    messageType = options.messageType || null,
                    self = options.viewObject || this,
                    mainContainer = self.isMobile ? $('.GACMobile .tuple-container') : $('.GACDesktop .tuple-container');

                if (!self.isBiddingInProgress && messageId && messageId !== self.messageId) {

                    $('.bemail-' + messageId).remove();
                    $('.alert-expiry-status-' + messageId).remove();
                    
                    // home page outbid tuple removal 
                    if ( typeof window.htimestamp !== "undefined" && window.htimestamp.outbid ) {
                        window.htimestamp.outbid.removeOutbidTuple({
                            listingId: $('.' + messageId).attr("data-listing-id")
                        })
                    }
                    
                    $('.' + messageId).slideUp(function(){
                        $(this).remove();
                        if(mainContainer.find('.gaTuple').outerHeight() < 10){
                            // self.hideAlertContainer();
                            self.displayAlertsView({ view: 'error' });
                        }
                    });   
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:removeListing|" + e, null, 1);
            }
        },

        /*
            - initiate listners on property changes 
        */
        initPropertyListner: function (options) {

            try {
                options = options || {};
                var self = options.viewObject || this,
                    initState = options.initState || false,
                    propertyIdList = options.propertyIdList || [],
                    index,
                    listingNodeReference,
                    databaseUrl;

                if (self.firebaseApp.auth().currentUser) {

                    //GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "Initialising listener on listings node - Firebase session is available ", "userId: " + self.userId + " | listing ids: " + JSON.stringify(propertyIdList));

                    propertyIdList = $.uniqList({key:'listingId', list: propertyIdList});

                    for (index = 0; index < propertyIdList.length; index++) {
                        
                        databaseUrl = 'listings/' + propertyIdList[index].listingId;
                        if(self.tenantId) { 
                            databaseUrl = self.tenantId + "/" + databaseUrl;
                        }
                        self.listingNodeUrls.push(databaseUrl);

                        listingNodeReference = self.firebaseApp.database().ref(databaseUrl);
                        listingNodeReference.on('value', function (snapshot) {

                            self.updateListingData({
                                snapshot: snapshot,
                                viewObject: self,
                                initState: initState
                            });

                            initState = false;

                        }, function (error) {
                            self.notificationError({
                                error: error,
                                viewObject: self,
                                databaseUrl: databaseUrl,
                                event: 'value'
                            });
                        });
                    }

                } else {
                   // GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "Initialising listener on listings node - Firebase session is not available ", "userId: " + self.userId);
                    options.callback = self.initPropertyListner;
                    options.viewObject = self;
                    self.authenticate(options);
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:initPropertyListner|" + e, null, 1);
            }

        },
        updateListingData: function (options) {

            try {
                options = options || {};

                var self = options.viewObject || this,
                    snapshot = options.snapshot || {},
                    listingId = snapshot.key || null,
                    auctionDetails = snapshot.child('auctionDetails') || {},
                    maxBidAmount = auctionDetails.child('maxBidAmount').val(),
                    minBidAmount = auctionDetails.child('minBidAmount').val(),
                    highestBidAmount = auctionDetails.child('highestBidAmount').val() || null,
                    bidIncrement = auctionDetails.child('bidIncrement').val(),
                    bidIncrementMultiplier = auctionDetails.child('bidIncrementMultiplier').val() || 0,
                    customBidIncrementMultiplier = auctionDetails.child('customBidIncrementMultiplier').val() || 0,
                    auctionEndTime = auctionDetails.child('auctionEndTime').val(),
                    auctionTimeLeft = Number(auctionDetails.child('auctionTimeLeft').val()) || 0,
                    numberOfBids = auctionDetails.child('numberOfBids').val() || 0,
                    reservePriceMet = auctionDetails.child('reservePriceMet').val() || null,
                    nextBidAmount = auctionDetails.child('nextpossibleBidAmount').val() || null,
                    messageId,
                    errorMessage,
                    ownItNowClicked = 'N',
                    mainContainer = self.isMobile ? $('.GACMobile .tuple-container') : $('.GACDesktop .tuple-container');

                var customBidEnabled = $.cookie('customBidEnabled');
                if(customBidEnabled == 'Y' && customBidIncrementMultiplier != 0){
                  bidIncrementMultiplier = customBidIncrementMultiplier;  
                }
                if (!nextBidAmount) {
                    nextBidAmount = Number(highestBidAmount) + Number(bidIncrement);
                }
                
                GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "Listing got update", "listingId: " + listingId + " | highestBidAmount: " + highestBidAmount + " | initState: " + options.initState || '' + " | nextBidAmount: " + nextBidAmount);

                // iniate the timer for auction time left 
                if (auctionTimeLeft && (auctionTimeLeft > 0) && listingId) {

                    // Remove timer object if its already initiated on 'listingId'
                    self.removeTimerObject({
                        viewObject: self,
                        listingIds: [listingId]
                    });

                    self.initiateTimer({
                        timeLeft: auctionTimeLeft,
                        listingId: listingId,
                        viewObject: self
                    });
                }
 
                $('.' + listingId).attr("data-bid-min-inc", bidIncrementMultiplier);
                $('.' + listingId).attr("data-bid-inc", bidIncrement);
                $('.' + listingId).attr("data-max-bid", maxBidAmount);
                $('.' + listingId).attr("data-min-bid", minBidAmount);
                $('.' + listingId).attr("data-next-bid-amount", nextBidAmount);
                
                if(highestBidAmount)
                    $('.' + listingId + ' .current-bid').text("$" + self.formatMoney(highestBidAmount));

                
                if(nextBidAmount && !self.backupBidAlert)
                    $('.' + listingId + ' .ga-bid-amount-custom').val("$" + self.formatMoney(nextBidAmount));
                
                    
                
                //This will update the PDP bid amount input box
               // $('.' + listingId + ' .bid-plus-minus input').val("$" + nextBidAmount.formatMoney());
                $('.' + listingId + ' .gh-bid-count').text(numberOfBids);

                //Update PDP page attributes
                var url = window.location.href;
                if(url.indexOf('/property/')>-1 && window.golbalPDPView)
                {
                    golbalPDPView.updateBidCenterListingData({});
                }



                $('.ga-error-bid').html(" ").slideUp();

                // If confirm bid pane opened, update bid amount, buyer premium amount and error message
                // There is a posibility of having multiple tuples with same listing id, iterate through
                var listings = mainContainer.find('.' + listingId + ' .confirm-bid-expand'),
                    event = new Event('input', { 'bubbles': true, 'cancelable': true }),
                    elem;
                for(var i = 0; i < listings.length; i++) {

                    messageId = $(listings[i]).attr('data-message');

                    // Check if confirm pane is opened 
                    if($(listings[i]).is(':visible')){

                        // If user clicked own it now, then don't update 
                        ownItNowClicked = $(listings[i]).find('.confirm-bid-' + messageId).attr("data-ownitnow-clicked");
                        
                        if (ownItNowClicked === 'N') {

                            // Trigger input event which will calculate BP along with placebid validations.
                            elem = $(listings[i]).find('.ga-bid-amount-custom');
                            if (elem.length)
                                elem[0].dispatchEvent(event);

                            errorMessage = saveListDrawerContent.globalAlerts.errorMessages['OUTBID_ERROR'] || 'Oops! Someone outbided on this property.';
                            errorMessage = errorMessage.replace("###BID_AMOUNT###", '$' + self.formatMoney(highestBidAmount));

                            // Chekc if error message already displayed.
                            if($(listings[i]).find(".ga-bid-confirm-msg-container").hasClass('hide')){
                                $(listings[i]).find(".ga-bid-confirm-msg-container").removeClass("hide").find(".ga-bid-confirm-msg").html(errorMessage).show("slow");            
                            } else {
                                //$(listings[i]).find(".ga-bid-confirm-msg-container").find(".ga-bid-confirm-msg").html(errorMessage).show("slow");            
                            }
                        }

                    }
                }

                if (reservePriceMet && reservePriceMet === 'Y') {
                    $('.' + listingId + ' .reserve-met').removeClass('hide');
                } else {
                    $('.' + listingId + ' .reserve-met').addClass('hide');
                }

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:updateListingData|"+ e, null, 1);
            }

        },
        
        initiateCountdownTimer: function(options) {
            try {

                options = options || {};
                var timeLeft = options.timeLeft || 0,
                    parentElement = options.parentElement || null,
                    self = options.viewObject || this,
                    timer,
                    timerObject;

                timer = setInterval(function(){

                    timeLeft = timeLeft - 1000;
                    
                    timerObject = self.calculateTimeLeft({ millseconds: timeLeft, sendIndividualUnits: true });                        

                    if (timerObject.days.value === 0 &&
                        timerObject.hours.value === 0 &&
                        timerObject.minutes.value === 0 &&
                        timerObject.seconds.value === 0 ) {
                            clearInterval(timer);
                            $("." + parentElement + ' .cif-timer').hide();
                    } 

                    $("." + parentElement + ' .hr-value').text(timerObject.hours.value);
                    $("." + parentElement + ' .mn-value').text(timerObject.minutes.value);
                    $("." + parentElement + ' .sec-value').text(timerObject.seconds.value);

                    $("." + parentElement + ' .hr-label').text(timerObject.hours.label);
                    $("." + parentElement + ' .mn-label').text(timerObject.minutes.label);
                    $("." + parentElement + ' .sec-label').text(timerObject.seconds.label);
                    
                }, 1000);

                self.timerObjects.push({
                    listingId: parentElement,
                    timerObject: timer
                });

            } catch(e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:initiateCountdownTimer|" + e, null, 1);
            }            
        },

        /**
         *   Initate timer on specific listing 
         *   @params: listingId, timeLeft, viewObject 
         */
        initiateTimer: function(options){
            try {

                options = options || {};
                var timeLeft = options.timeLeft || 0,
                    listingId = options.listingId || null,
                    self = options.viewObject || this,
                    timer,
                    calculatedTimeLeft;

                timer = setInterval(function(){

                    timeLeft = timeLeft - 1000;
                    
                    calculatedTimeLeft = self.calculateTimeLeft({ millseconds: timeLeft });                        

                    if(calculatedTimeLeft) {
                        $('.' + listingId + ' .ga-time-left-counter').text(calculatedTimeLeft);
                        $('.' + listingId + ' .ga-time-left-counter-desc').text('Time Left');                        
                    }

                    if(timeLeft <= 0 ){
                        $('.' + listingId + ' .ga-time-left-counter').text('');
                        $('.' + listingId + ' .ga-time-left-counter-desc').text('Auction Ended');
                        clearInterval(timer);
                    } 

                }, 1000);

                self.timerObjects.push({
                    listingId: listingId,
                    timerObject: timer
                });

            } catch(e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:initiateTimer|" + e, null, 1);
            }
        },
        /**
        *   Remove 'setInterval' object if already initated for specific listing 
        *   @params: listingIds
        */
        removeTimerObject: function(options) {
            try {

                options = options || {};
                var self = options.viewObject || this,
                    index,
                    listingIds = options.listingIds || [],
                    timer = []; 

                if(!listingIds.length && !self.timerObjects.length) return;

                for(index = 0 ; index <= listingIds.length ; index++ ){
                    
                    timer = _.where(self.timerObjects, {listingId: listingIds[index]}) || []; 

                    if( timer.length && timer[0].timerObject ) {
                        clearInterval(timer[0].timerObject);
                        var removeIndex = self.timerObjects.map(function(item) { return item.listingId; }).indexOf(listingIds[index]);
                        self.timerObjects.splice(removeIndex, 1);

                    }
                }

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:removeTimerObject|" + e, null, 1);
            }
        },
        calculateTimeLeft: function (options) {

            try {
                options = options || {};
                var millseconds = options.millseconds || 0,
                    sendIndividualUnits = options.sendIndividualUnits || null,
                    sendAllUnits = options.sendAllUnits || null,
                    seconds,
                    minutes,
                    hours,
                    days;

                millseconds = millseconds / 1000;

                seconds = Math.floor((millseconds) % 60);
                minutes = Math.floor((millseconds / 60) % 60);
                hours = Math.floor((millseconds / ( 60 * 60)) % 24);
                days = Math.floor(millseconds / ( 60 * 60 * 24));
              
                if(sendAllUnits){

                    var arr = []
                    if (days > 0) {
                        arr.push( (days > 1 ) ? days + ' days' : days + ' day');
                    }
                    if(hours > 0) {
                        arr.push((hours > 1 )? hours + ' hours' : hours + ' hour');
                    } 
                    if(minutes > 0) {
                        if(days < 1) {
                            arr.push((minutes > 1)? minutes + ' minutes' : minutes + ' minute');
                        }
                    } 
                    if (seconds > 0){
                        if( days < 1 && hours < 1 ) {
                            arr.push((seconds > 1) ? seconds + ' seconds' : seconds + ' second');
                        }
                    }

                    if(arr.length === 0) {
                        return null;
                    } else {
                        return arr[0] + ' ' + (arr[1] || '');
                    }

                } else if (sendIndividualUnits){ 

                    return {
                        days: {
                            value: days,
                            label: (days != 1 ) ? 'Days' : 'Day'
                        }, 
                        hours: {
                            value: hours,
                            label: (hours != 1) ? 'Hours': 'Hour'
                        },
                        minutes: {
                            value: minutes,
                            label: (minutes != 1) ? 'Minutes' : 'Minute'
                        },
                        seconds: {
                            value: seconds,
                            label: (seconds != 1) ? 'Seconds' : 'Second'
                        }
                    };

                }else if (days > 0) {
                    return days > 1 ? days + ' days' : days + ' day';
                } else if (hours > 0) {
                    return hours > 1 ? hours + ' hours' : hours + ' hour';
                } else if (minutes > 0) {
                    return minutes > 1 ? minutes + ' minutes' : minutes + ' minute';
                } else if (seconds > 0) {
                    return seconds > 1 ? seconds + ' seconds' : seconds + ' second';
                } else {
                    return null;
                } 

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:calculateTimeLeft|" + e, null, 1);
            }
        },

        showAlertContainer: function (e) {

            try {
                var self = this;
                e.stopPropagation();
                if(newSkinning){
                    clearInterval(self.alert_timer);
                }
                if($(e.target).hasClass("drawer-saved-cancel")){
                    $(".h-save-search-icon").removeClass("active");
                }
                if($(e.target).hasClass("drawer-cancel")){
                    $(".global-alerts-icon").removeClass("active");
                }
                // If new version is enabled open notificatino drawer
                this.showAlertsDrawer();
                

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:showAlertContainer|" + e, null, 1);
            }

        },
        walletOpen: function(){
            proxyBidView.fromGA = true;
            proxyBidView.getUserWalletStatus(proxyBidView);

        },
        showAlertsDrawer: function(options) {
            try {
                var status, origin;
                var alertOpt = (typeof options == 'undefined')?{}:options;
                if ($(window).width()<768) {
                    origin = "Mobile";
                    //$('#myModal').is(':visible');
                    if(($(".gobal-alerts-drawer-popup").data('bs.modal') || {}).isShown){
                        $('.gobal-alerts-drawer-popup').modal('hide');
                        //make lockbox code container access false
                        if(typeof golbalPDPView !== "undefined") {
                            golbalPDPView.showAlertsContainer = false;  
                            $('html, body').animate({
                                scrollTop: $(".rently-head-text").offset().top - 150
                            }, 500); 
                        }
                        proxyBidView.fromGA = false;
                        this.submitFromAlertView = false;
                    } else {
                        $('.gobal-alerts-drawer-popup').modal('show');
                        $(".bid-success-container").slideUp();
                        this.renderGlobalAlerts(alertOpt);
                        this.submitFromAlertView = true;

                    }
                    
                } else {
                    origin = "Desktop";
                    $('.gobal-alerts-drawer-slider').toggleClass('is-visible');
                    $('.gobal-alerts-overlay').toggleClass('is-visible');

                    if(!$('.gobal-alerts-drawer-slider').hasClass('is-visible')){
                
                        $('body').removeClass('no-scroll');    
                        status = 'Closed';
                        $('.tuple-container').removeClass('error-template1');
                        $('.tuple-container').removeClass('error-template2');
                        $('.tuple-container').removeClass('error-template3');

                        $(".bid-success-container").slideUp();

                        //make lockbox code container access false
                        if(typeof golbalPDPView !== "undefined") {
                            golbalPDPView.showAlertsContainer = false;  
                            
                        }
                        this.submitFromAlertView = false;

                        proxyBidView.fromGA = false;

                    } else {
                        this.submitFromAlertView = true;

                        $('body').addClass('no-scroll');
                        status = 'Opened';
                        this.renderGlobalAlerts(alertOpt);
                    }
                }
                $.each($('img'), function() {
                  if ( $(this).attr('lazy-src') ) {
                      var source = $(this).attr('lazy-src');
                      $(this).attr('src', source);
                      $(this).removeAttr('lazy-src');
                  }
                });
                var gaLabel = 'visitorId: ' + $.cookie('visitorId');
                var userSession = window.userSession || {};
                var userId = userSession.userId || null;
                if(userId){
                    gaLabel += '|userId: '+ userId;
                }
                GAUtils.eventTracking.trackevent("Global Alerts",'Header - Alerts Click', gaLabel + '|Status - ' + status, "");   
                
                if (this.smsAlert || this.marketingAlerts.length) {
                    var userAlertsTrackList = window.localStorage && window.localStorage.getItem('userAlertsTrackList') || null;
                    userAlertsTrackList = userAlertsTrackList && JSON.parse(userAlertsTrackList) || {}; 
                    if (userId)
                        userAlertsTrackList[userId] = "Y";
                    if (window.localStorage)
                        window.localStorage.setItem("userAlertsTrackList", JSON.stringify(userAlertsTrackList));
                }

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:showAlertsDrawer|" + e, null, 1);
            }
        },

        resetUserAlertsCount: function(){
            try {

                var url = typeof contextPath !== "undefined" ? contextPath : '',
                    userId = typeof window.userSession !== "undefined" && window.userSession.userId || null;
                var self = this;
                if (!userId) return;

                url += '/portal/resetMessageCount/' + userId;

                Utils.fetch({
                    url: url,
                    type: 'POST',
                    callback: function(params) {
                        params = params || {};

                        var response = params.response || {},
                            status = params.status || 'ERROR',
                            apiStatus = response.status || 'ERROR';
                        
                        if (status === 'SUCCESS' && apiStatus === 'SUCCESS') 
                            GAUtils.eventTracking.trackevent("Global Alerts",'Alerts count reset success', 'visitorId: ' + $.cookie('visitorId')+  "| PageName:"+self.pageName);   
                    }
                });

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:resetUserNotificationCount|" + error, null, 1);
            }
        },
        verifyIdologyStatus: function(){
            try{
                var self = this,
                    userSession = window.userSession || {},
                    userId = userSession.userId || null,
                    userStatus = userSession.userStatus || null,
                    userIdologyStatus = userSession.idologyStatus || false;

                // Verify user is idology verified or not.
                
                var url= '/portal/lean-regn/isIdologyVerificationRequired';
                self.showLoader();
                jQuery.ajax({
                    url: contextPath + url,
                    type: 'GET',
                    contentType:'application/json',
                    success: function (responseData) {
                        if (userStatus === 'ACTIVE' && responseData.data == true) {
                            userStatus = 'IDOLOGY';
                            self.showDefaultNotification({
                                userId: userId,
                                userStatus: userStatus
                            });
                        }else  {
                            self.getPropertyDetails('', self.showAlerts);
                        }
                    },
                    error: function (error) {
                        GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:verifyIdologyStatus|" + error, null, 1);
                    } 
                }); 

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:verifyIdologyStatus|" + e, null, 1);
            } 
        },
        renderGlobalAlerts: function (options) {
            try {
                var self = this,
                    userSession = window.userSession || {},
                    userId = userSession.userId || null,
                    userStatus = userSession.userStatus || null,
                    globalAlertsCmsNode = typeof saveListDrawerContent !== 'undefined' && saveListDrawerContent.globalAlerts.labels || {};

                $('.tuple-container').slideDown();
               
                if(typeof golbalPDPView !== "undefined" && golbalPDPView.showAlertsContainer) {
                    $(".title-container .title-text, .mobile-title .title-txt").text(globalAlertsCmsNode.lockBoxAccess);
                    $(".title-container .title-icon-lockbox").css('display','inline-block');
                    $(".title-container .title-icon").css('display','none');
                    $(".ga-register-container").addClass("active");
                } else if(typeof options != 'undefined' && typeof options.content !='undefined' && typeof options.content.title !='undefined' && typeof options.content.title !='' ){
                    $(".title-container .title-text, .mobile-title .title-txt").text(options.content.title);
                    $(".title-container .title-icon-lockbox").css('display','none');
                    $(".title-container .title-icon").css('display','inline-block');
                } else {
                    if(this.isMobile) {
                        $(".mobile-title .title-txt").text(globalAlertsCmsNode.alerts);
                    } else {
                        $(".title-container .title-text").text(globalAlertsCmsNode.alerts);
                        $(".title-container .title-icon-lockbox").css('display','none');
                        $(".title-container .title-icon").css('display','inline-block');
                    }
                    
                }

                if (userId && userStatus === 'ACTIVE') {
                    self.displayAlertsView({ view: 'loader' });
                    self.verifyIdologyStatus();
                } else {
                    self.showDefaultNotification({
                        userId: userId,
                        userStatus: userStatus
                    });
                }
                if(typeof $.cookie('subscribeEmail') != 'undefined' && $.cookie('subscribeEmail') !='' && !(typeof window.userSession != 'undefined' && typeof window.userSession.user != 'undefined' && typeof window.userSession.user.loggedIn != 'undefined' && window.userSession.user.loggedIn)){
                    $('.leanHeadParent').html("<div class=\"completeProfile\">"+saveListDrawerContent.alertViewSec.favDrawer.title+"</div>");
                    $('.ga-register-container .ga-footer').addClass('hide');
                    $('.ga-register-container .registerDefault .btn-primary').html(saveListDrawerContent.alertViewSec.favDrawer.buttonName);
                }
            }
            catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:renderGlobalAlerts|" + e, null, 1);
            }
        },
        hideAlertContainer: function () {

            try {
                var tupleContainer = this.isMobile ? $('.GACMobile .tuple-container') : $('.GACDesktop .tuple-container'),
                    listingIds = [];

                this.messageId = null;
                $(".bid-success-container").slideUp();
                $("#ga-loader-mask").fadeOut(400);
                $('body').removeClass('modal-open');
                $('body').removeClass('no-scroll');

                tupleContainer.removeClass('error-template1');
                tupleContainer.slideUp(function () {
                    tupleContainer.removeClass('error-template2');
                    tupleContainer.removeClass('error-template3');

                    // change color of tip of alert drawer
                    $(".globalAlertTuples").removeClass('active');
                });

                // Remove firebase listeners
                this.detachListners({
                    databaseUrls: this.listingNodeUrls
                });

                listingIds = this.listingNodeUrls.map(function(listingIdUrl){
                    return listingIdUrl.substring(listingIdUrl.lastIndexOf('listings/') + 9, listingIdUrl.length);
                });

                proxyBidView.fromGA = false;

                // Remove timer object if its already initiated on 'listingId'
                this.removeTimerObject({
                    viewObject: this,
                    listingIds: listingIds
                });

                // Hide drawer 
                $('.gobal-alerts-drawer-slider').removeClass('is-visible');
                $('.gobal-alerts-overlay').removeClass('is-visible');

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:hideAlertContainer|" + e, null, 1);
            }
        },
        //Service to get All alerts
        getPropertyDetails: function (options, callback) {
            var self = this;

            try {

                jQuery.httpRequest({   
                    url: contextPath + '/portal/allAlerts',
                    type: 'GET',
                    success: function (responseData) {
                        var response = responseData || {},
                            status = response.status || 'ERROR',
                            alerts = response.alerts || '';

                        if (status === 'SESSION_REQUIRED') {
                            self.showDefaultNotification({});
                        } else if (status === 'SUCCESS' && alerts) {
                            GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "Alerts are available ", "userId: " + self.userId + " | alerts count: " + alerts.length);
                            callback(null, {
                                self: self,
                                alerts: alerts
                            });
                        } else {
                            GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "Alerts are not available", "userId: " + self.userId);
                            callback(response.errorMessage || 'Error while getting token', {
                                self: self
                            });
                        }
                    },
                    error: function (error) {
                        GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:getPropertyDetails|" + error, null, 1);
                        callback(error);
                    }
                });
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:getPropertyDetails|" + e, null,1);
            }

        },


        //append the Template with alert response
        showAlerts: function (error, options) {

            try {
                options = options || {};
                //var thisObj = this;
                var self = options.self || this,
                    isNewTuple = options.isNewTuple || false,
                    object = ".gaTuple",
                    initState = options.initState || false,
                    alertContent = {},
                    mainContainer = self.isMobile ? $('.GACMobile .tuple-container') : $('.GACDesktop .tuple-container'),
                    globalAlertsCmsNode = typeof saveListDrawerContent !== 'undefined' && saveListDrawerContent.globalAlerts || {},
                    isSaveSearchAlertsEnabled = globalAlertsCmsNode.hubzuConfig && globalAlertsCmsNode.hubzuConfig.enableSaveSearchAlerts || 'N',
                    isCIFreminderAlertsEnabled = globalAlertsCmsNode.hubzuConfig && globalAlertsCmsNode.hubzuConfig.enableCIFReminderAlerts || 'Y',
                    containerWidth,
                    index,
                    alertType,
                    listingIds = [],
                    renderedContent = "", propCount, sName, sDate, saveSearchTitle, saveSearchDesc, savedUrl = {}, saveSearchData, propText = "properties were";
                   // alertContent.specialFeatures.push("OWN_IT_NOW_ENABLED"); /// OIN
                if (error) {
                    GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "load all alerts failure|userId: " + options.self.userId + " | error: " + error, null, 1);
                    mainContainer.addClass('error-template1 error-template2 error-template3');
                    self.displayAlertsView({ view: 'error' });
                    return;
                }

                // Reset alerts count 
                if ($('.gobal-alerts-drawer-slider').hasClass('is-visible') || ($(".gobal-alerts-drawer-popup").data('bs.modal') || {}).isShown) {
                    self.resetUserAlertsCount();
                }
                
                if (!options.alerts.length) {
                    mainContainer.addClass('error-template1 error-template2 error-template3');

                    if((!self.smsAlert && (!self.marketingAlerts.length || !$(".ga-whats-new-container").is(":visible"))) ) {
                        self.displayAlertsView({ view: 'error' });
                        self.clearNotification();  
                    } else {
                        self.hideLoader();
                    }
                    
                    // to check if sms alert is seen already 
                    if (self.isUserSeenAlert()) self.clearNotification();
                    return;
                }

                for (index = 0 ; index < options.alerts.length ; index++) {

                    alertContent = options.alerts[index] || {};
                    alertType = alertContent.type || null;

                    if (alertType){

                        // Out bid tuple data modifications and then render 
                        if ((alertType === 'DRIVEN_BID_WATCHER_ALERT' || alertType === 'BACKUP_BID_EXPIRY' || alertType === 'BACKUPBID' || alertType === 'OUTBID' || alertType === 'NEW_BID' || alertType === 'AUCTION_EXTENDED') 
                            && Utils.isGlobalAlertsEnabled({level: 'bid'}) ){

                            // add bid alert listing Ids to an array to initiate firebase listener
                            if(alertContent.listingId) listingIds.push({listingId: alertContent.listingId});

                            if(alertContent.specialFeatures && alertContent.specialFeatures.indexOf('NO_BUYER_PREMIUM') >= 0) {
                                alertContent.noBuyersPremium = 'Y';
                            } else {
                                alertContent.noBuyersPremium = 'N';
                            }
                            
                            if(alertContent.buyItNowPrice && alertContent.messageStatus !== 'expired') {
                                alertContent.buyItNowFormattedPrice = self.formatMoney(alertContent.buyItNowPrice); 
                            }
                            
                            alertContent.areaSqurFeet = alertContent.areaSqurFeet && self.formatMoney(alertContent.areaSqurFeet) || alertContent.areaSqurFeet;

                            alertContent.contextPath = contextPath;
                            alertContent.content = saveListDrawerContent.globalAlerts;
                            alertContent.userType = window.userSession && window.userSession.userType || 'BUYR';

                            if(alertType == "BACKUP_BID_EXPIRY" && alertContent.backupBidExpiryDays){
                                alertContent.message = saveListDrawerContent.globalAlerts.messages[alertType].replace("###DAYS_COUNT###", alertContent.backupBidExpiryDays);
                            }

                            if(alertType == "DRIVEN_BID_WATCHER_ALERT"){
                                alertContent.message = saveListDrawerContent.globalAlerts.messages[alertType];
                            }

                            console.log(alertContent);
                            alertContent.autoBidExperimentEnable = 'N';
                            if(alertType === 'OUTBID' && self.showAutoBid){
                                alertContent.autoBidExperimentEnable = 'Y';
                            }

                            alertContent.isActiveBidRefinementEnabled = 'N';
                            alertContent.lowerThresholdIncrementMultiplier = 0;
                            alertContent.upperThresholdIncrementMultiplier = 0;
                            alertContent.upperThresholdAmount = 0;
                            alertContent.lowerThresholdAmount = 0;

                            if(alertContent.bidCenterAlertDetails.activeBidRefinementEnabled){
                                alertContent.isActiveBidRefinementEnabled = 'Y';
                            }

                            if(alertContent.bidCenterAlertDetails.lowerThresholdAmount != ''){
                                alertContent.lowerThresholdAmount = alertContent.bidCenterAlertDetails.lowerThresholdAmount;
                                alertContent.lowerThresholdIncrementMultiplier = alertContent.bidCenterAlertDetails.bidIncrementMultiplier;
                            }

                            if(alertContent.bidCenterAlertDetails.upperThresholdAmount != ''){
                                alertContent.upperThresholdAmount = alertContent.bidCenterAlertDetails.upperThresholdAmount;
                                alertContent.upperThresholdIncrementMultiplier = alertContent.bidCenterAlertDetails.bidIncrementMultiplier;
                            }

                            console.log("========",alertContent);
                            
                            if(newSkinning){
                                renderedContent += alertTupleTemplateNewSkin(alertContent);
                            }else{
                                renderedContent += alertTupleTemplate(alertContent);    
                            }
                            

                        } else if (alertType === 'SAVE_SEARCH' && isSaveSearchAlertsEnabled === 'Y') {

                            // ###S_COUNT### new properties were added to your saved search ###S_NAME### on ###S_DATE###
                            propCount = Number(alertContent.newPropertyCount) || '';
                            sName = alertContent.saveSearchResponse.searchName || '';
                            sDate = alertContent.lastNotifiedOn || '';
                            saveSearchTitle = saveListDrawerContent.globalAlerts.saveSearchLabels.saveSearchTitle || '';
                            saveSearchDesc = saveListDrawerContent.globalAlerts.saveSearchLabels.saveSearchDesc || '';
                            savedUrl.searchBy = alertContent.saveSearchResponse.searchBy;
                            savedUrl.baseSearchUrl = alertContent.saveSearchResponse.baseSearchUrl;
                            var alertProdCategory = false;
                            var saveSearchCriteriaList = (alertContent.saveSearchResponse.searchCriteriaList && alertContent.saveSearchResponse.searchCriteriaList.length > 0) ? alertContent.saveSearchResponse.searchCriteriaList : '';
                            if(saveSearchCriteriaList){
                                for(var x = 0; x < saveSearchCriteriaList.length;  x++){
                                    var criteriaKey = saveSearchCriteriaList[x].criteriaKey;
                                    var criteriaValue = saveSearchCriteriaList[x].criteriaValue;
                                    if(criteriaKey == 'category' && criteriaValue == 'npl'){
                                        alertProdCategory = true;
                                    }
                                }
                            }

                            if(alertProdCategory) {
                                // Always add Sorty by Most popular filter for all NPL Save Search URLs
                                alertContent.saveSearchResponse.searchCriteriaList.push({
                                    criteriaKey: "sortBy",
                                    criteriaValue: "sort=MostPopular",
                                    criteriaValueDescription: "Most Popular",
                                    id: null,
                                    searchId: null
                                });
                            } else {
                                // Always add Sorty by time filter for all Save Search URLs
                                alertContent.saveSearchResponse.searchCriteriaList.push({
                                    criteriaKey: "sortBy",
                                    criteriaValue: "sort=TimeLeftHL",
                                    criteriaValueDescription: "Time left (Hi - Lo)",
                                    id: null,
                                    searchId: null
                                });
                            }
                            

                            saveSearchData = saveListDrawerView.formUrlNew(savedUrl, alertContent.saveSearchResponse.searchCriteriaList) || {};

                            alertContent.saveSearchUrl = (saveSearchData && saveSearchData.url ) ? saveSearchData.url : '#'

                            if(saveSearchDesc) {

                                propText = (propCount && propCount > 1) ? "properties were" : "property was";

                                saveSearchDesc = saveSearchDesc.replace("###PROP_TEXT###", propText);
                                saveSearchDesc = saveSearchDesc.replace("###S_COUNT###", propCount);
                                saveSearchDesc = saveSearchDesc.replace("###S_NAME###", "'" + sName + "'");
                                saveSearchDesc = saveSearchDesc.replace("###S_DATE###", sDate);
                            }

                            alertContent.saveSearchTitle = saveSearchTitle;
                            alertContent.saveSearchDesc = saveSearchDesc;
                            if(newSkinning){
                                renderedContent += saveSearchTupleTemplateNewSkin(alertContent);
                            }else{
                                renderedContent += saveSearchTupleTemplate(alertContent);
                            }
                        } else if (alertType === 'CIF_REMINDER' && isCIFreminderAlertsEnabled === 'Y') {

                            var title = globalAlertsCmsNode.cifAlerts.alertTitle,
                                actionLabel = globalAlertsCmsNode.cifAlerts.alertAction, 
                                actionLink = contextPath + alertContent.listingUrl,
                                description = globalAlertsCmsNode.cifAlerts.alertDescription;

                            var address = alertContent.propertyAddress.streetNumber +  ' ' + alertContent.propertyAddress.streetName + ', ' + alertContent.propertyAddress.city + ', ';
                            address += alertContent.propertyAddress.state + ' ' + alertContent.propertyAddress.zip;

                            description = description.replace("###PROP_ADDRESS###", address);
                            description = description.replace('###PROP_LINK###', actionLink);

                            alertContent.description = description;
                            alertContent.title = title;
                            alertContent.actionLabel = actionLabel;
                            alertContent.actionLink = actionLink;

                            renderedContent += cifReminderTupleTemplate(alertContent);

                            // iniate the timer for auction time left 
                            if (alertContent.cifDetails && alertContent.cifDetails.timeLeft && alertContent.messageId) {

                                // Remove timer object if its already initiated on 'messageId'
                                self.removeTimerObject({
                                    viewObject: self,
                                    listingIds: [alertContent.messageId]
                                });

                                self.initiateCountdownTimer({
                                    timeLeft: alertContent.cifDetails.timeLeft,
                                    parentElement: alertContent.messageId,
                                    viewObject: self
                                });
                            }

                        }
                        else if(alertType == "FC_AUCTION_ALERT"){
                                var alertName = alertContent.alertName || null,
                                    alertAmount = (typeof alertContent.transferedFundAmount !=='undefined' && alertContent.transferedFundAmount != '')? self.formatMoney(alertContent.transferedFundAmount) : 0 ;
                                if(alertName){
                                    self.showDefaultNotification({
                                        userId: self.userId,
                                        userStatus: "ADDFUNDS"
                                        });
                                    var alertKeyValue = globalAlertsCmsNode.fcAuctionAlert['FC_STATUS_KEY'][alertName];
                                    var content = globalAlertsCmsNode.fcAuctionAlert['FC_STATUS'][alertKeyValue] || globalAlertsCmsNode.fcAuctionAlert['FC_STATUS']['default'];
                                    content.description = content.desc.replaceAll('###AMOUNT_NO###',alertAmount).replaceAll('###ACCOUNT_NO###',alertContent.accountEndingWith).replaceAll('###PROP_ADDRESS###',alertContent.location);
                                    renderedContent = renderedContent + addWalletTemplate(content);
                                }
                                
                                 
                               
                        }

                    } else {
                        // log GA   
                    }

                }

                if (isNewTuple) {
                    $(self.el).find(object).prepend(renderedContent);
                } else {
                    $(self.el).find(object).html(renderedContent);
                }

                // if content is not available, show error message.
                if ( renderedContent.length > 0 ) {
                    self.displayAlertsView({view: 'gaTuple' });
                } else {
                    mainContainer.addClass('error-template1 error-template2 error-template3');
                    self.displayAlertsView({ view: 'error' });
                }

                self.initPropertyListner({
                    viewObject: self,
                    propertyIdList: listingIds,
                    initState: initState,
                });

                self.initPopOver();
                

                if(newSkinning && $(".ga-tuple-item.active").length > 0){
                    self.initiateTimerGlobalAlert();    
                }
                

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:showAlerts|" + e, null, 1);
            }
        },
        initiateTimerGlobalAlert: function(){
            
            try {
            
                var self = this;
                self.alert_timer = setInterval(function(){

                    
                    $(".ga-tuple-item.active").each(function() {
                        var messageId = $(this).attr('id');                        
                        
                        var timeLeft = parseInt($(this).find(".timer").attr('data-timeleft')) - 1000;
                        
                        timeLeft = timeLeft < 0 ? 0 : timeLeft;
                        if(timeLeft >= 0){
                            var formattedTime = self.calculateTimeLeft({ millseconds: timeLeft, sendIndividualUnits: true }) || '';
                            var time_remaining = "";
                            if(formattedTime.days.value >= 1){
                                time_remaining = formattedTime.days.value+'d '+formattedTime.hours.value+'h '+formattedTime.minutes.value+'m';
                            }else{
                                time_remaining = formattedTime.hours.value+'h '+formattedTime.minutes.value+'m '+formattedTime.seconds.value+'s'
                            }
                            $(this).find(".timer-value").html(time_remaining).show();
                        }else{
                            $(this).find(".timer-value").hide();
                        }
                        $(this).find(".timer").attr('data-timeleft',timeLeft);
                        
                    });
                    // Remove setInterval if time left becomes null
                    //if(!timeLeft) clearInterval(timer);

                }, 1000);

                

            } catch(e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:initiateTimerGlobalAlert|" + e, null, 1);
            }
        },
        
        getBackupBidDetails : function(options){
            var self = this,
            url = contextPathPortal + '/v2.0/getAvailableBackUpBidAmount/'+options.listingId+'/'+ options.bidAmount;
            options.viewObject= self;
            Utils.fetch({
                type: "GET",
                url: url,
                viewObject: self,
                options: options,
                callback: self.getBackupBidDetailsCallback
            });
        },

        getBackupBidDetailsCallback : function(options){
            try{
            options = options || {};
            console.log(options);
            var self= options.options.viewObject || this,
                response = options.response || {},
                inputData = options.options.options || {}, 
                apiStatus = options.status || 'ERROR',
                status = response.status || "FAILURE",
                data = response.data || {},
                userMessage;
                if(response.status == "SUCCESS"){
                    // var parentId = $('#bidAmount').parents('.required')
                    // $(parentId).removeClass('error');
                    if(response.data.hideDisplayMessage){
                        userMessage = '';
                    } else {
                        userMessage = (saveListDrawerContent.backupBidContent.error_code['user_message']).replace('###MIN_AMOUNT###',response.data.formattedMinBackUpBidLimit).replace('###MAX_AMOUNT###',response.data.formattedMaxBackUpBidLimit).replace('###INC_AMOUNT###',response.data.formattedIncrementMultplier).replace('###NEXT_AMOUNT###',response.data.formattedMinBackUpBidLimit);
                    }
                    
                    if($('.'+inputData.listingId+' .backup-bid-label').html()==''){
                        $('.'+inputData.listingId+' .backup-bid-label').html(userMessage);
                    }

                    if(response.data.hideDisplayMessage){
                        $('.'+inputData.listingId+' .backup-bid-label').html('');
                    }
                    if(response.data.errorCode && !(self.propsBackupEnabled)){
                        userMessage = (saveListDrawerContent.backupBidContent.error_code[response.data.errorCode]).replace('###MIN_AMOUNT###',response.data.formattedMinBackUpBidLimit).replace('###MAX_AMOUNT###',response.data.formattedMaxBackUpBidLimit).replace('###INC_AMOUNT###',response.data.formattedIncrementMultplier).replace('###NEXT_AMOUNT###',response.data.formattedMinBackUpBidLimit);
                        $('.'+inputData.listingId+' .ga-error-bid').html(userMessage).slideDown();
                        $('.'+inputData.listingId+' .ga-bp-value').html("-");
                        $('.'+inputData.listingId+' .ga-bid-totalDue').html("-");
                    } else {
                        $('.'+inputData.listingId+' .ga-error-bid').html('').slideUp();
                        self.propsBackupEnabled = false;
                        var amount = inputData.bidAmount;
                        bidWizardModel.attributes.bidAmountDetail.bidAmount = amount;
                        self.calculateBuyersPremium(inputData);
                    }
                } else {
                    $('.'+inputData.listingId+' .ga-error-bid').html('').slideUp();
                    self.propsBackupEnabled = false;
                    var amount = inputData.bidAmount;
                    bidWizardModel.attributes.bidAmountDetail.bidAmount = amount;
                    self.calculateBuyersPremium(inputData);
                }
            } catch(e) {
                GAUtils.eventTracking.trackevent("Error:New Bid","file:bidView.js,function:getBackupBidDetailsCallback", error);
            }
        },

        displayAlertsView: function (options) {
            try {
                options = options || {};
                var self = this,
                    view = options.view || 'error',
                    availableViews = ['gaTuple', 'loader', 'error'],
                    index;

                for (index = 0; index < availableViews.length; index++) {
                    $('.tuple-container .' + availableViews[index]).hide();
                }

                $('.tuple-container .' + view).fadeIn('slow');

                if (view === 'gaTuple') {
                    self.attachEventsOnBidInput();
                    if(self.autoBidGlobalAlertsEnabled){
                        self.attachEventsOnAutoBidInput(); 
                    }
                }

                if(view === 'gaTuple' && self.outBidData && self.outBidData.fromOutBid){
                    $('.'+self.outBidData.listingId+' .ga-place-bid').trigger('click');
                    self.outBidData.fromOutBid = false;
                    var scrollPos = parseInt($('.gobal-alerts-drawer-slider .'+self.outBidData.listingId).position().top)
                    $('.gobal-alerts-drawer-slider').animate({
                        scrollTop: scrollPos
                    }, 500);
                }

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:displayAlertsView|" + e, null, 1);
            }
        },

        attachEventsOnBidInput: function () {
            try {
                var self = this;
                
                // New slider 
                $('.gobal-alerts-drawer-container .ga-bid-amount-custom').each(function (e) {

                    $(this).off("keydown").on("keydown", function (e) {

                        // Allow: backspace, delete, tab, escape, enter,  (comma), and $
                        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 188, 52]) !== -1 ||
                            // Allow: Ctrl+A
                            (e.keyCode === 65 && e.ctrlKey === true) ||
                            // Allow: home, end, left, right, down, up
                            (e.keyCode >= 35 && e.keyCode <= 40)) {
                            // let it happen, don't do anything
                            return;
                        }
                        // Ensure that it is a number and stop the keypress
                        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                            e.preventDefault();
                        }
                    });

                    this.oninput = function(event) {
                        var messageId = $(this).attr("data-message"),
                            listingId = $(this).attr("data-listingId"),
                            bidAmount = $(this).val().replace(/[\$,]/g, '') || '',
                            mainContainer = self.isMobile ? $('.GACMobile .tuple-container') : $('.GACDesktop .tuple-container'),
                            isConfirmPaneOpened =  mainContainer.find('#' + messageId + ' .confirm-bid-expand').is(':visible');

                        // Update hidden form value
                        $(".bid-form-" + messageId + " .bidValue" + messageId).val(bidAmount);
                        GAUtils.eventTracking.trackevent("Global Alerts", "Global Alerts - bid amount change event triggered",  self.userDetails+ " | visitorId:"+ self.visitorId + " | listingId: "+listingId+" | Message Id:" + messageId + " | Confirm Pane opened:" + isConfirmPaneOpened +  "| PageName:"+self.pageName);
                        if(self.backupBidAlert){
                            if(bidAmount == ''){
                                $('.'+listingId+' .ga-error-bid').html(saveListDrawerContent.backupBidContent.error_code['empty_mess']).slideDown();
                                $('.'+listingId+' .ga-bp-value').html("-");
                                $('.'+listingId+' .ga-bid-totalDue').html("-");
                                return;
                            }
                            $(this).val("$" + self.formatMoney(bidAmount));
                            self.getBackupBidDetails({bidAmount: bidAmount, messageId: messageId, listingId: listingId, disableValidation:true });
                        } else {
                            if(self.placeBidValidation({messageId: messageId, bidAmount: bidAmount})){
                                if(messageId && isConfirmPaneOpened) { 
                                    self.calculateBuyersPremium({bidAmount: bidAmount, messageId: messageId, listingId: listingId });
                                }
                            }
                        }
                        
                    }
                });
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:attachEventsOnBidInput|" + e, null, 1);
            }
        },

        attachEventsOnAutoBidInput: function () {
            try {
                var self = this;
                
                // New slider 
                $('.gobal-alerts-drawer-container .b_autobid_amount').each(function (e) {

                    $(this).off("keydown").on("keydown", function (e) {

                        // Allow: backspace, delete, tab, escape, enter,  (comma), and $
                        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 188, 52]) !== -1 ||
                            // Allow: Ctrl+A
                            (e.keyCode === 65 && e.ctrlKey === true) ||
                            // Allow: home, end, left, right, down, up
                            (e.keyCode >= 35 && e.keyCode <= 40)) {
                            // let it happen, don't do anything
                            return;
                        }
                        // Ensure that it is a number and stop the keypress
                        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                            e.preventDefault();
                        }
                    });

                    this.oninput = function(event) {
                        self.checkAutoBidAmount(event);
                    };
                });
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:attachEventsOnBidInput|" + e, null, 1);
            }
        },

        showMorePropertyDetails: function (e) {

            try {

                var self = this,
                    mainContainer = self.isMobile ? $('.GACMobile .tuple-container') : $('.GACDesktop .tuple-container'),
                    mainContainerDiv = self.isMobile ? ".GACMobile" : ".GACDesktop",
                    messageId = $(e.target).attr("data-message"),
                    listingId = $(e.target).attr("data-listingId"),
                    object = $("." + messageId);

                this.userDetails = this.setUserDetails();    

                GAUtils.eventTracking.trackevent("Global Alerts", "Global alerts - View property details clicked ", this.userDetails + " | visitorId: "+ this.visitorId + " | listingId: " + listingId + " | Message Id: " + messageId +  "| PageName:"+this.pageName);

                /* Collapse and existing tuples if any */

                // Hide error messages if any
                $(".ga-bid-confirm-msg-container").addClass('hide');

                // enable confirm buttons if any disabled
                $('.ga-confirm-bid').css({ "pointer-events": "all", "opacity": "1" });
                //$(".ga-tuple-item").find(".timer").show();
                // collapce other tuples confirm details   
                mainContainer.find(".confirm-bid-expand").hide();
                mainContainer.find(".ga-property-address").show("fade");
                mainContainer.find(".ga-ownitnow-container").show("fade");
                mainContainer.find('.ga-tuple-item').removeClass("tuple-expand-bg");
                mainContainer.find(".hide-details").show();
                mainContainer.find(".close-details").addClass("hide");

                mainContainer.find(".tuple-data-expand").slideUp("slow");

                /* Expand the current tuple */
                mainContainer.find(object).addClass("tuple-expand-bg");

                mainContainer.find(object).find(".hide-details").hide();
                mainContainer.find(object).find(".close-details").removeClass("hide");

                mainContainer.find(object).find(".tuple-data-expand").slideDown("slow"
                ,function(){
                    var target = $(mainContainerDiv + " ." + messageId) || null, topOffset;
                    topOffset = target && target.offset().top || null;
                    if(target && topOffset) {
                        if(newSkinning && window.screen.width < 768){

                        }
                        else{
                            mainContainer.animate({ scrollTop: topOffset - 30});     
                        }
                    }
                });

            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:showMorePropertyDetails|" + exception, null, 1);
            }
        },

        hideViewDetails: function (e) {
            try {
                var self = this,
                    messageId = $(e.target).attr("data-message"),
                    listingId = $(e.target).attr("data-listingId"),
                    messageContainer = $("." + messageId),
                    mainContainer = self.isMobile ? $('.GACMobile .tuple-container') : $('.GACDesktop .tuple-container');

                this.userDetails = this.setUserDetails();                

                mainContainer.find(messageContainer).removeClass("tuple-expand-bg");
                mainContainer.find(messageContainer).find(".hide-details").show();
                mainContainer.find(messageContainer).find(".close-details").addClass("hide");
                mainContainer.find(messageContainer).find(".tuple-data-expand").slideUp("slow");

                GAUtils.eventTracking.trackevent("Global Alerts", "Global Alerts - Close property details clicked",  this.userDetails + " | visitorId: "+ this.visitorId + " | listingId: " +listingId+ " | Message Id:" + messageId +  "| PageName:"+this.pageName);

            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:hideViewDetails|" + exception, null, 1);
            }
        },
        showNotification: function (options) {
            try {
                var self = options.viewObject || this,
                    unReadMessageCount = options.unReadMessageCount || 0,
                    alertMessage = options.alertMessage || '',
                    initState = options.initState || false,
                    displayTime = options.displayTime || 5000,
                    notificationAudioElem, 
                    alertCount = 0,
                    snapshot = options.snapshot || null,
                    data = snapshot.child('data').val() || [],
                    messageType = snapshot.child('lastMessageType').val() || '',
                    enabledFeatures = window.userSession && window.userSession.enabledFeatures || [];

                self.userDetails = self.setUserDetails();

                GAUtils.eventTracking.trackevent("Global Alerts", "GlobalAlerts - User got notication from firebase ", self.userDetails +
                    " | pendingMessages: " + unReadMessageCount + " | initState: " + initState +  "| PageName:"+self.pageName, null, 1);

                if (unReadMessageCount && unReadMessageCount > 0) {

                    // Check for enabled features 
                    if(enabledFeatures.length) {

                        var abTestFlag = saveListDrawerContent.globalAlerts.abTesting.abTestEnabled || 'N';

                        if (abTestFlag === 'Y' && data.length) {
                            
                            enabledFeatures.forEach(function(feature) {
                                alertCount += Number(data[feature] || 0);
                            });
                        } else {
                            alertCount = unReadMessageCount;
                        }

                        if (alertCount > 0) {
                                $('.globalAlertsContainer .alertCount, .user-alerts-count').removeClass('hide');
                                $('.globalAlertsContainer .alertCount, .user-alerts-count').html(alertCount);
                        } else {
                            
                            self.clearNotification();
                        }

                        if (!initState && alertMessage && enabledFeatures.indexOf(messageType) > -1 ) {
                            $('.globalAlertsContainer .alert-header-popcontent').html(alertMessage);
                            $(".header-alertcount-tooltip").fadeIn();
        
                            // Add sticky header
                            if(($(window).scrollTop() > 350) && ($('.header-container').closest('.home').length >0)){
                                $('.header-container').addClass('hubzu-sticky-header');
                            }
                            
                            setTimeout(function () {
                                $(".header-alertcount-tooltip").fadeOut();
                            }, displayTime);
        
                            /* Play notification sound */
                            // if(saveListDrawerContent.globalAlerts.hubzuConfig.enableSoundNotification === 'Y'){
                            //     notificationAudioElem = $('#globalAlertsNotificationAudio');
                            //     if(notificationAudioElem) {
                            //         notificationAudioElem[0].play();   
                            //     }    
                            // }
                        }

                        if(enabledFeatures.indexOf('AUTOBID_REPEATBID_GLOBAL_ALERTS') != -1){
                            self.autoBidGlobalAlertsEnabled = 'Y';
                        }

                    } else {
                        self.clearNotification();
                    }
                    
                } else {
                    self.clearNotification();
                }

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:showNotification|" + e, null, 1);
            }
        },
        isUserSeenAlert: function(){
            try {
                
                var userId = typeof window.userSession !== "undefined" && window.userSession.userId || null,
                    status = false,
                    userAlertsTrackList;

                if (!userId) return status;
                
                userAlertsTrackList = typeof window.localStorage !== "undefined" && window.localStorage.getItem('userAlertsTrackList') || null;   
                userAlertsTrackList = userAlertsTrackList && JSON.parse(userAlertsTrackList) || {}; 
                status = (userAlertsTrackList[userId] && userAlertsTrackList[userId] === 'Y') ? true : false;
                
                return status;

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:isUserSeenAlert|" + error, null, 1);  
            }
        },
        clearNotification: function () {

            $('.globalAlertsContainer .alertCount, .user-alerts-count').addClass('hide');
            $('.globalAlertsContainer .alertCount, .user-alerts-count').empty();

            if((this.smsAlert || ($(".ga-whats-new-container").is(":visible") && this.marketingAlerts.length))) {
                if ( this.isUserSeenAlert() ) return;
                $('.globalAlertsContainer .alertCount, .user-alerts-count').removeClass('hide');
                $('.globalAlertsContainer .alertCount, .user-alerts-count').html("1");
            } else {
                $(".opt-sms-container, .opt-in-success").addClass("hide");
            }

        },
        notificationError: function (options) {
            // This listner will trigger if we get any error - Ex: authentication errors 
            // NOTE: Even though we turn off event listner, this is getting triggered once. 
            var error = options.error || 'Firebase internal error',
                self = options.viewObject || this,
                event = options.event || null,
                databaseUrl = options.databaseUrl || null;

            //if(self.firebaseApp.auth().currentUser !== null) {
            GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "Firebase listner error - userId: " + self.userId +
                " | Event: " + event + " | databaseUrl: " + databaseUrl + error, null, 1);
            //}
        
        },
        
        signOutFirebase: function (options) {
            try {

                options = options || {};

                var self = this,
                    isUserSignedOut = options.isUserSignedOut || false;

                self.firebaseApp.auth().signOut()
                    .then(function () {

                        self.isSignedOut = isUserSignedOut ? true : false;
                        //GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "Firebase signout success - ", "userId: " + self.userId);

                    })
                    .catch(function (error) {
                        //  GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "Firebase signout failure - userId: " + self.userId, error);
                    });

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:signOutFirebase|" + e, null, 1);
            }
        },
        detachListners: function (options) {
            try {
                var userId = options.userId || '',
                    index = 0,
                    self = this,
                    databaseUrls = options.databaseUrls || [],
                    summaryNodeReference;

                //GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "Firebase - Detaching listeners", " userId: " + self.userId + " | databaseUrls:" + databaseUrls);

                for (index = 0; index < databaseUrls.length; index++) {
                    summaryNodeReference = self.firebaseApp.database().ref(databaseUrls[index]);
                    summaryNodeReference.off();
                }

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:detachListners|" + e, null, 1);
            }
        },
        detachHandler: function (dataSnapshot) {
           // GAUtils.eventTracking.trackevent("DEBUG:GlobalAlerts", "Firebase Detached successfully ", dataSnapshot.child('unReadMessages').val());
        },

        showLoader: function () {
            $(".globalAlertTuples .loader").show();
        },

        hideLoader: function () {
            $(".globalAlertTuples .loader").hide();
        },

        calculateBuyersPremium: function(options){
            try {
                var self = this,
                    messageId = options.messageId || '',
                    listingId = options.listingId || '',
                    ownItNowAmount = options.ownItNowAmount || null,
                    listingObject = self.getListingValues({messageId: messageId}),
                    mainContainer = self.isMobile ? $('.GACMobile .tuple-container') : $('.GACDesktop .tuple-container'),
                    currentTupleObject = mainContainer.find("." + messageId),
                    bidvalue = Number(options.bidAmount) || 0;
                    options.disableValidation = (ownItNowAmount !== null || ownItNowAmount !== '') ? true : false;
                // if (bidvalue % listingObject.bidIncrement != 0) {
                //     bidvalue = Math.floor(bidvalue / listingObject.bidIncrement) * listingObject.bidIncrement;
                //     mainContainer.find(currentTupleObject).find(".confirm-bid-expand").find(".bid-val-" + messageId).val("$" + bidvalue.formatMoney());
                // }
                
                if(!self.backupBidAlert && !self.backupBidExpiryAlert){
                    if (listingObject.isFcOnline == 'Y' && typeof listingObject.reservePrice != 'undefined' &&  typeof listingObject.bidIncrementPostReserve != 'undefined' && bidvalue > listingObject.reservePrice && (bidvalue-listingObject.reservePrice) % listingObject.bidIncrementPostReserve != 0) {
                        bidvalue = listingObject.minBidAmount + Math.floor((bidvalue-listingObject.minBidAmount)/listingObject.bidIncrement) * listingObject.bidIncrement;
                        mainContainer.find(currentTupleObject).find(".confirm-bid-expand").find(".bid-val-" + messageId).val("$" + self.formatMoney(bidvalue));
                    }else if(listingObject.isFcOnline == 'Y' && typeof listingObject.reservePrice != 'undefined' &&  typeof listingObject.bidIncrementPostReserve != 'undefined' && bidvalue > listingObject.reservePrice && (bidvalue-listingObject.reservePrice) % listingObject.bidIncrementPostReserve == 0){
                        bidvalue = bidvalue;
                    }if(listingObject.isActiveBidRefinementEnabled === 'Y' && parseInt(bidvalue) >= parseInt(listingObject.lowerThresholdAmount) && parseInt(bidvalue < listingObject.upperThresholdAmount)){
                        bidvalue = listingObject.minBidAmount + Math.floor((bidvalue-listingObject.minBidAmount)/listingObject.lowerThresholdIncrementMultiplierr) * listingObject.lowerThresholdIncrementMultiplier;
                       mainContainer.find(currentTupleObject).find(".confirm-bid-expand").find(".bid-val-" + messageId).val("$" + self.formatMoney(bidvalue));
                    }else if(listingObject.isActiveBidRefinementEnabled === 'Y' && parseInt(bidvalue) >= parseInt(listingObject.upperThresholdAmount)){
                        //bidvalue = parseInt(bidvalue) ;
                        //mainContainer.find(currentTupleObject).find(".confirm-bid-expand").find(".bid-val-" + messageId).val("$" + self.formatMoney(bidvalue));
                        
                        bidvalue = listingObject.minBidAmount + Math.floor((bidvalue-listingObject.minBidAmount)/listingObject.upperThresholdIncrementMultiplier) * listingObject.upperThresholdIncrementMultiplier;
                        mainContainer.find(currentTupleObject).find(".confirm-bid-expand").find(".bid-val-" + messageId).val("$" + self.formatMoney(bidvalue));
                    }else if ((bidvalue-listingObject.minBidAmount) % listingObject.bidIncrementMultiplier != 0 && ownItNowAmount === null) {
                        bidvalue = listingObject.minBidAmount + Math.floor((bidvalue-listingObject.minBidAmount)/listingObject.bidIncrement) * listingObject.bidIncrement;
                        mainContainer.find(currentTupleObject).find(".confirm-bid-expand").find(".bid-val-" + messageId).val("$" + self.formatMoney(bidvalue));
                    }
                }
                
                var placeValidation = (options && options.disableValidation) ? true : self.placeBidValidation({messageId: messageId, bidAmount: bidvalue});
                if(placeValidation) {
                    // Disable 'confirm bid' button
                    mainContainer.find(currentTupleObject).find(".ga-confirm-bid").css({ "pointer-events": "none", "opacity": "0.5" });

                    var saleType = currentTupleObject.attr('data-finance-type') || "CASH",
                        url = contextPathPortal + '/v2.0/getBidAmountDetail/' + listingId + '/'+ saleType +'/' + bidvalue;

                    if (self.buyersPremiumRequest != null) self.buyersPremiumRequest.abort();

                    self.buyersPremiumRequest = $.ajax({
                        url: url,
                        type: "GET",
                        success: function(response) {
                            self.updateBuyerPremiumDetails({
                                response: response,
                                options: {
                                    viewObject: self,
                                    currentTupleObject: currentTupleObject,
                                    mainContainer: mainContainer,
                                },
                                status: "SUCCESS"
                            })
                        },
                        error: function(xhr, error, status) {
                            self.updateBuyerPremiumDetails({
                                response: {},
                                options: {
                                    viewObject: self,
                                    currentTupleObject: currentTupleObject,
                                    mainContainer: mainContainer,
                                },
                                status: "ERROR"
                            })
                        },
                        complete: function(params) {
                            mainContainer.find(currentTupleObject).find(".ga-confirm-bid, .ga-bid-plus-minus").css({ "pointer-events": "all", "opacity": "1" });
                        }
                    })
                }  
                        
            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:calculateBuyersPremium|" + exception, null, 1);
            }
        },

        updateBuyerPremiumDetails: function(responseData){

            try {
            
                var response = responseData.response || {},
                    options = responseData.options || {},
                    inputData = options.data || {},
                    apiStatus = responseData.status || 'ERROR',
                    status = response.status || 'ERROR',
                    self = options.viewObject || this,
                    currentTupleObject = options.currentTupleObject,
                    mainContainer = options.mainContainer,
                    technologyFee,
                    data = response.data || {};

                // console.log(' apiStatus: ', apiStatus, ' Status: ', status);
                if ( apiStatus === "SUCCESS" && status === "SUCCESS" ) {

                    bidWizardModel.attributes.bidAmountDetail.bidAmount = data.bidAmount;
                    bidWizardModel.attributes.bidAmountDetail.buyerPremiumAmount = (data.buyerPremiumFormatted) ? parseInt(data.buyerPremiumFormatted.split('$')[1].replace(/,|%/g , "")) : null;
                    bidWizardModel.attributes.bidAmountDetail.emdAmount = (data.emdAmountFormatted) ? parseInt(data.emdAmountFormatted.split('$')[1].replace(/,|%/g , "")) : null;

                    mainContainer.find(currentTupleObject).find(".ga-confirm-bid").css({ "pointer-events": "all", "opacity": "1" });

                    if (data.premiumPaidBy == 'SELLER') {
                        if(self.backupBidAlert){
                            if(data.bidAmount !== null && data.bidAmount !== '' && data.bidAmount !== 0 && data.bidAmount !== '0'){
                                mainContainer.find(currentTupleObject).find(".ga-bid-totalDue").html(_.escape(data.bidAmountFormatted));
                            } else {
                                mainContainer.find(currentTupleObject).find(".ga-bid-totalDue").html(_.escape('-'));
                            }
                        } else {
                            mainContainer.find(currentTupleObject).find(".ga-bid-totalDue").html(_.escape(data.bidAmountFormatted));
                        }
                    } else {
                        if(self.backupBidAlert){
                            if(data.totalDueAmount !== null && data.totalDueAmount !== '' && data.totalDueAmount !== 0 && data.totalDueAmount !== '0'){
                                mainContainer.find(currentTupleObject).find(".ga-bid-totalDue").html(_.escape(data.totalDueFormatted));
                            } else {
                                mainContainer.find(currentTupleObject).find(".ga-bid-totalDue").html(_.escape('-'));
                            }
                        } else {
                            mainContainer.find(currentTupleObject).find(".ga-bid-totalDue").html(_.escape(data.totalDueFormatted));
                        }
                    }
                    if(self.backupBidAlert && bidWizardModel.attributes.premiumPaidBy && bidWizardModel.attributes.premiumPaidBy == "SELLER"){
                        bidWizardModel.attributes.bidAmountDetail.buyerPremiumAmount = null;
                        bidWizardModel.attributes.bidAmountDetail.buyerPremiumFormatted = null
                    }
                    
                    
                    // if(data.buyerPremiumPercentage != ''){
                    if(self.backupBidAlert){
                        if(data.buyerPremiumPercentage !== null && data.buyerPremiumPercentage !== '' && data.buyerPremiumPercentage !== 0 && data.buyerPremiumPercentage !== '0'){
                            mainContainer.find(currentTupleObject).find(".ga-bp-percentage").text("("+data.buyerPremiumPercentage+"%)");
                        }else{
                            mainContainer.find(currentTupleObject).find(".ga-bp-percentage").html('-');
                        }
                    } else if((data.buyerPremiumPercentage !== null && data.buyerPremiumPercentage !== '' && data.buyerPremiumPercentage !== 0 && data.buyerPremiumPercentage !== '0')) {
                        mainContainer.find(currentTupleObject).find(".ga-bp-percentage").text("("+data.buyerPremiumPercentage+"%)");
                    }

                    if(data.reservePrice != ''){
                        mainContainer.find(currentTupleObject).attr('data-reservePrice',data.reservePrice);
                     }else{
                        mainContainer.find(currentTupleObject).attr('data-reservePrice','0');

                     }
                     if(typeof data.nextPossibleBidAmountPostReserve  != 'undefined' && data.nextPossibleBidAmountPostReserve != ''){
                        mainContainer.find(currentTupleObject).attr('data-nextPossibleBidAmountPostReserve',data.nextPossibleBidAmountPostReserve);
                     }else{
                        mainContainer.find(currentTupleObject).attr('data-nextPossibleBidAmountPostReserve','0');

                     }
                     if(typeof data.bidIncrementPostReserve != 'undefined' && data.bidIncrementPostReserve != ''){
                        mainContainer.find(currentTupleObject).attr('data-bidIncrementPostReserve',data.bidIncrementPostReserve);
                     }else{
                        mainContainer.find(currentTupleObject).attr('data-nextPossibleBidAmountPostReserve','0');

                     }

                     if(data.lowerThresholdAmount != ''){
                        mainContainer.find(currentTupleObject).attr('data-lowerThresholdAmount',data.lowerThresholdAmount);
                        mainContainer.find(currentTupleObject).attr('data-lowerThresholdIncrementMultiplier',data.lowerThresholdIncrementMultiplier);
                     }else{
                        mainContainer.find(currentTupleObject).attr('data-lowerThresholdAmount','0');

                     }
                     if(data.upperThresholdAmount != ''){
                        mainContainer.find(currentTupleObject).attr('data-upperThresholdAmount',data.upperThresholdAmount);
                        mainContainer.find(currentTupleObject).attr('data-upperThresholdIncrementMultiplier',data.upperThresholdIncrementMultiplier);
                     }else{
                        mainContainer.find(currentTupleObject).attr('data-upperThresholdAmount','0');

                     }
                     if(data.isActiveBidRefinementEnabled != ''){
                       
                        mainContainer.find(currentTupleObject).attr('data-isActiveBidRefinementEnabled',data.isActiveBidRefinementEnabled);
                     }else{
                        mainContainer.find(currentTupleObject).attr('data-isActiveBidRefinementEnabled','N');

                     }
                     if(data.incrementMulitplierPostReserve != ''){
                        mainContainer.find(currentTupleObject).attr('data-incrementMulitplierPostReserve',data.incrementMulitplierPostReserve);
                     }else{
                        mainContainer.find(currentTupleObject).attr('data-incrementMulitplierPostReserve','0');

                     }
                    if(self.backupBidAlert){
                        if(data.buyerPremiumPercentage !== null && data.buyerPremiumPercentage !== '' && data.buyerPremiumPercentage !== 0 && data.buyerPremiumPercentage !== '0'){
                            mainContainer.find(currentTupleObject).find(".ga-bp-value").text(data.buyerPremiumFormatted);
                        } else {
                            mainContainer.find(currentTupleObject).find(".ga-bp-value").text('-');
                        }
                    } else {
                        mainContainer.find(currentTupleObject).find(".ga-bp-value").text(data.buyerPremiumFormatted);
                    }

                    technologyFee = data.technologyFeeFormatted || 0;
                    if(technologyFee === 0 || technologyFee === '$0') {
                        mainContainer.find(currentTupleObject).find('.technology-fee-container').hide();
                    } else {
                        mainContainer.find(currentTupleObject).find(".ga-techfee-value").text(technologyFee).show();
                    }
                    
                } else {
                    // inconsistant data and log error    
                    GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:updateBuyerPremiumDetails|Inconsistant data while rendering updatd buyer premium details. Status: " + status, null, 1);
                }
            
            } catch(exception) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:updateBuyerPremiumDetails|" + exception, null, 1);
            }
        },

        calculateBidIncrement: function (e) {
            try {

                var self = this,
                    currentElement = $(e.target),
                    messageId = currentElement.attr("data-message"),
                    listingId = currentElement.attr("data-listingid"),
                    isAutoBidEnabled = currentElement.attr("data-auto-bid") === 'Y' ? true : false,
                    currentBidAmountObject = currentElement.prev(),
                    listingObject = self.getListingValues({currentBidAmountObject: currentBidAmountObject});

                $('.ga-error-' + messageId).html(" ").slideUp();

                this.userDetails = this.setUserDetails();
                GAUtils.eventTracking.trackevent("Global Alerts", "Global Alerts - Calculate bid increment button clicked ", this.userDetails + " | visitorId: "+ this.visitorId + " | listingId: " + listingId + " | messageId: " + messageId +  "| PageName:"+this.pageName);

                listingObject.bidAmount = listingObject.bidValue;

                // var upperIncConfig = saveListDrawerContent.placeBidContent.bidConfig.highTreshInc;
                // if(listingObject.isActiveBidRefinementEnabled && listingObject.isActiveBidRefinementEnabled === 'Y'){
                //     if(listingObject.bidAmount >= (parseInt(listingObject.upperThresholdAmount) + (parseInt(listingObject.upperThresholdIncrementMultiplier) * parseInt(upperIncConfig)))){
                //         return;
                //     }
                // }

                placeBidView.calculateBidIncrement({
                    currEle: currentElement,
                    viewObject : self,
                    bidAmountCalVal: listingObject,
                    callback: function(responseData){
                        self.updateActionElements({
                            viewObject: self,
                            responseData: responseData,
                            messageId: messageId,
                            listingId: listingId,
                            isAutoBidEnabled : isAutoBidEnabled,
                            currentElement: currentElement
                        });
                    }
                });

            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:calculateBidIncrement|" + exception, null, 1);
            }
        },

        calculateBidReduction: function (e) {
            try {

                var self = this,
                    currentElement = $(e.target),
                    messageId = currentElement.attr("data-message"),
                    listingId = currentElement.attr("data-listingid"),
                    isAutoBidEnabled = currentElement.attr("data-auto-bid") === 'Y' ? true : false,
                    currentBidAmountObject = currentElement.next(),
                    listingObject = self.getListingValues({currentBidAmountObject: currentBidAmountObject});
                    console.log('currentBidAmountObject', currentBidAmountObject);

                $('.ga-error-' + messageId).html(" ").slideUp();

                this.userDetails = this.setUserDetails();
                GAUtils.eventTracking.trackevent("Global Alerts", "Global Alerts - Calculate bid reduction button clicked ", this.userDetails + " | visitorId: "+ this.visitorId + " | listingId: " + listingId + " | messageId: " + messageId +  "| PageName:"+this.pageName);

                listingObject.bidAmount = listingObject.bidValue;

                var slabAmount = (listingObject.currentHighestBid > parseInt(listingObject.lowerThresholdAmount))? (listingObject.currentHighestBid) : parseInt(listingObject.lowerThresholdAmount);
                var highSlabAmount = (listingObject.currentHighestBid > parseInt(listingObject.upperThresholdAmount))? (listingObject.currentHighestBid ) : parseInt(listingObject.upperThresholdAmount);

                var lowerIncConfig = saveListDrawerContent.placeBidContent.bidConfig.lowTreshInc;
                var upperIncConfig = saveListDrawerContent.placeBidContent.bidConfig.highTreshInc;
                if(listingObject.isActiveBidRefinementEnabled && listingObject.isActiveBidRefinementEnabled === 'Y'){
                    if(listingObject.currentHighestBid >= listingObject.bidAmount && listingObject.bidAmount >= (parseInt(slabAmount) + (parseInt(listingObject.lowerThresholdIncrementMultiplier) * parseInt(lowerIncConfig)))){
                        return;
                    } 
                    // else if(listingObject.bidAmount > (parseInt(highSlabAmount) + (parseInt(listingObject.upperThresholdIncrementMultiplier) * parseInt(upperIncConfig)))){
                    //     listingObject.bidAmount = listingObject.nextBidAmount;
                    // }
                }

                placeBidView.calculateBidReduction({
                    currEle: currentElement,
                    viewObject : self,
                    bidAmountCalVal: listingObject,
                    callback: function(responseData){
                        self.updateActionElements({
                            viewObject: self,
                            listingId: listingId,
                            responseData: responseData,
                            isAutoBidEnabled : isAutoBidEnabled,
                            messageId: messageId,
                            currentElement: currentElement
                        });
                    }
                });

            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:calculateBidReduction|" + exception, null, 1);
            }

        },

        updateActionElements:function(options){
            try{
                options = options || {};
                
                var messageId = options.messageId || null,
                    self = options.viewObject || this,
                    listingId= options.listingId || '',
                    isAutoBidEnabled = options.isAutoBidEnabled || false,
                    currentElement = options.currentElement || null,
                    responseData = options.responseData || {},
                    listingObject = responseData.bidAmountCalVal || {},
                    bidAmount = listingObject.bidAmount || null,
                    isGreaterThanBidAmount = listingObject.isGreaterThanBidAmount || null,
                    actionObject = listingObject.enableDisableAction || null,
                    errorMessage = '';

                // if entered amount is greater than bid amount, show error
                if (isGreaterThanBidAmount && isGreaterThanBidAmount === 'Y'){
                    errorMessage = saveListDrawerContent.placeBidContent.placeBidError["MAX_BID_REACHED"] || '';
                    $('.ga-error-' + messageId).html(errorMessage).slideDown();    
                    actionObject.minus = 'enabled';
                }

                // Update centralized bid model 
                bidWizardModel.attributes.bidAmountDetail.bidAmount = bidAmount;
                

                // update bid value 
                $(".bidValue" + messageId).val(bidAmount);
                $(".bid-val-" + messageId).val("$" + self.formatMoney(bidAmount));

                // if confirm bid pane is opened, calculate buyer premium and update.
                if(currentElement.hasClass("confirm-bid-btn")){
                    self.calculateBuyersPremium({bidAmount: bidAmount, messageId: messageId, listingId: listingId});
                }

                // Enable or disable plus/minus buttons
                self.enableDisableButtons({ actionObject: actionObject, messageId: messageId });

                //Active nid refinement changes
                if(isAutoBidEnabled){
                    if(listingObject.isActiveBidRefinementEnabled && listingObject.isActiveBidRefinementEnabled === 'Y'){
                        if(bidAmount >= (parseInt(listingObject.lowerThresholdAmount) + (parseInt(listingObject.nextBidAmount) % parseInt(listingObject.lowerThresholdIncrementMultiplier)))){
                            $('.tuple-expand-bg .auto-bid-section-global-alerts').hide('slide', {direction:'right'}, 400);
                        } else {
                            $('.tuple-expand-bg .auto-bid-section-global-alerts').show('slide', {direction:'right'}, 400);
                        }
                    }
                }
                
          } catch(e){
              GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:updateActionElements|" + e, null, 1);
          }
        },
        formatMoney: function(value) {
            try {
                value = value || {};
                var formatter;

                if (!value) return 0;
                formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0
                })
                value = formatter.format(value).replace('$', '');

                return value;

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:formatMoney|" + error, null, 1);
            }
        },
        enableDisableButtons: function(options){

            try {
                options = options || {};

                var actionObject = options.actionObject || null,
                    messageId = options.messageId || null;

                if(actionObject && messageId){
                    if(actionObject.plus && actionObject.plus === 'enabled'){
                        $('.bid-plus-' + messageId).removeClass('disable');
                    } else if(actionObject.plus && actionObject.plus === 'disabled'){
                        $('.bid-plus-' + messageId).addClass('disable');
                    }
                    if(actionObject.minus && actionObject.minus === 'enabled'){
                        $('.bid-minus-' + messageId).removeClass('disable');
                    } else if(actionObject.minus && actionObject.minus === 'disabled'){
                        $('.bid-minus-' + messageId).addClass('disable');
                    }
                }
            } catch(e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:enableDisableButtons|" + e, null, 1);
            }

        },
        placeBidValidation: function (options) {

            try {
                
                options = options || {};
                console.log(options);
                var bidval = Number(options.bidAmount) || 0,
                    messageId = options.messageId || '',
                    messageContainer = $("." + messageId),
                    self = options.viewObject || this,
                    mainContainer = self.isMobile ? $('.GACMobile .tuple-container') : $('.GACDesktop .tuple-container'),
                    listingObject = self.getListingValues({messageId: messageId}),
                    errorMessage = '',
                    errorCode,
                    errorMessageAmount,
                    actionObject = {},
                    oin = options;
                console.log(listingObject);
                if (bidval === 0 || isNaN(bidval)) {
                    errorCode = "EMPTY_BID_AMOUNT";
                    actionObject = {'plus':'enabled','minus':'enabled'};

                } else if (listingObject.isActiveBidRefinementEnabled != 'Y' && bidval < listingObject.minBidAmount) {

                    errorCode = "MIN_BID_AMOUNT";
                    errorMessageAmount = self.formatMoney(listingObject.minBidAmount);
                    actionObject = {'plus':'enabled','minus':'disabled'};

                } else if (listingObject.isFcOnline == 'Y' &&  listingObject.reservePrice!='' && listingObject.reservePrice == bidval){
                    errorCode = "RESERVE_PRICE_VAL";
                    errorMessageAmount = self.formatMoney(bidval +listingObject.bidIncrementPostReserve );
                    actionObject = {'plus':'enabled','minus':'enabled'};
                } else if (listingObject.isActiveBidRefinementEnabled != 'Y' && bidval > listingObject.maxBidAmount) {

                    errorCode = "MAX_BID_AMOUNT";
                    errorMessageAmount = self.formatMoney(listingObject.maxBidAmount);
                    actionObject = {'plus':'disabled','minus':'enabled'};

                // } else if((listingObject.isActiveBidRefinementEnabled === 'Y')&& (bidval >= listingObject.lowerThresholdAmount && bidval <= listingObject.upperThresholdAmount )){
                //     errorCode = "LOWER_BID_INCREMENT";
                //     errorMessageAmount = self.formatMoney(listingObject.lowerThresholdIncrementMultiplier);
                //     actionObject = {'plus':'enabled','minus':'enabled'};
				

                // } else if((listingObject.isActiveBidRefinementEnabled === 'Y')&& (bidval >= parseInt(listingObject.upperThresholdAmount))){
                //     errorCode = "UPPER_BID_INCREMENT";
                //     errorMessageAmount = self.formatMoney(listingObject.upperThresholdIncrementMultiplier);
                //     actionObject = {'plus':'enabled','minus':'enabled'};
                    

                } else {

                    /**
                    * 'bidIncrementMultiplier' - May be zero for some properties (i.e dynamic bidding not enabled)
                    * If bidIncrementMultiplier is 0, the check with bid increment range. 
                    */
                    if(listingObject.isFcOnline == 'Y' &&  listingObject.reservePrice!='' && listingObject.reservePrice < bidval){
                        if(listingObject.incrementMulitplierPostReserve !== 0 ) {

                            if((bidval-listingObject.minBidAmount) % listingObject.incrementMulitplierPostReserve !== 0) {
                                errorCode = "MIN_BID_INCREMENT";
                                errorMessageAmount = self.formatMoney(listingObject.incrementMulitplierPostReserve);
                                actionObject = {'plus':'enabled','minus':'enabled'};
                            }

                        } else {

                            if((bidval-listingObject.minBidAmount) % listingObject.bidIncrementPostReserve !== 0) {
                                errorCode = "MIN_BID_INCREMENT";
                                errorMessageAmount = self.formatMoney(listingObject.bidIncrementPostReserve);
                                actionObject = {'plus':'enabled','minus':'enabled'};
                            }

                        }
                    }
                    else if((listingObject.isActiveBidRefinementEnabled === 'Y')){


                        var slabAmount = (listingObject.minBidAmount > parseInt(listingObject.lowerThresholdAmount))? (listingObject.minBidAmount) : parseInt(listingObject.lowerThresholdAmount);
                        var highSlabAmount = (listingObject.minBidAmount > parseInt(listingObject.upperThresholdAmount))? (listingObject.minBidAmount ) : parseInt(listingObject.upperThresholdAmount);
                        
                        if (bidval < listingObject.minBidAmount) {
                            errorCode = "MIN_BID_AMOUNT";
                            errorMessageAmount = self.formatMoney(listingObject.minBidAmount);
                            actionObject = {'plus':'enabled','minus':'disabled'};
                        } else {
                            // if((listingObject.currentHighestBid > ((parseInt(listingObject.lowerThresholdAmount) + (parseInt(listingObject.nextBidAmount) % parseInt(listingObject.lowerThresholdIncrementMultiplier))))) && (bidval > (slabAmount + (parseInt(listingObject.lowerThresholdIncrementMultiplier) * parseInt(saveListDrawerContent.placeBidContent.bidConfig.lowTreshInc))) && bidval < parseInt(listingObject.upperThresholdAmount))){
                            //     errorCode = "LOWER_BID_INCREMENT";
                            //     errorMessageAmount = self.formatMoney(listingObject.lowerThresholdIncrementMultiplier);
                            //     actionObject = {'minus':'enabled','plus':'enabled'};
                            // } else 
                            if(parseInt(listingObject.nextBidAmount) < ((parseInt(listingObject.lowerThresholdAmount) + (parseInt(listingObject.nextBidAmount) % parseInt(listingObject.lowerThresholdIncrementMultiplier)))) && bidval > ((parseInt(listingObject.lowerThresholdAmount) + (parseInt(listingObject.nextBidAmount) % parseInt(listingObject.lowerThresholdIncrementMultiplier)))) && listingObject.nextBidAmount < parseInt(listingObject.upperThresholdAmount)){
                                errorCode = "MAX_ACTIVEBID_AMOUNT_ERROR";
                                var upperMaxBidAmount = (parseInt(listingObject.lowerThresholdAmount) + (parseInt(listingObject.nextBidAmount) % parseInt(listingObject.lowerThresholdIncrementMultiplier)))
                                errorMessageAmount = self.formatMoney(upperMaxBidAmount);
                                actionObject = {'minus':'enabled','plus':'disabled'};
                            } else if(bidval >= (parseInt(slabAmount) + parseInt(listingObject.lowerThresholdIncrementMultiplier) * parseInt(saveListDrawerContent.placeBidContent.bidConfig.lowTreshInc)) && parseInt(listingObject.currentHighestBid) < parseInt(listingObject.upperThresholdAmount) && (parseInt(listingObject.currentHighestBid) + parseInt(listingObject.bidIncrementMultiplier)) < parseInt(listingObject.lowerThresholdAmount)){
                                errorCode = "MAX_ACTIVEBID_AMOUNT_ERROR";
                                var upperMaxBidAmount = parseInt(listingObject.lowerThresholdAmount);
                                errorMessageAmount = self.formatMoney(upperMaxBidAmount);
                                actionObject = {'minus':'enabled','plus':'disabled'};
                            } else if(bidval >= (parseInt(slabAmount) + parseInt(listingObject.lowerThresholdIncrementMultiplier) * parseInt(saveListDrawerContent.placeBidContent.bidConfig.lowTreshInc)) && parseInt(listingObject.currentHighestBid) < parseInt(listingObject.upperThresholdAmount)){
                                errorCode = "MAX_ACTIVEBID_AMOUNT_ERROR";
                                var upperMaxBidAmount = (parseInt(listingObject.currentHighestBid) + parseInt(listingObject.lowerThresholdIncrementMultiplier) * parseInt(saveListDrawerContent.placeBidContent.bidConfig.lowTreshInc))
                                errorMessageAmount = self.formatMoney(upperMaxBidAmount);
                                actionObject = {'minus':'enabled','plus':'disabled'};
                            } else if(parseInt(listingObject.currentHighestBid) < parseInt(listingObject.upperThresholdAmount) && bidval > (parseInt(listingObject.upperThresholdAmount) + (parseInt(listingObject.nextBidAmount) % parseInt(listingObject.lowerThresholdIncrementMultiplier))) && bidval > (parseInt(listingObject.lowerThresholdAmount) + (parseInt(listingObject.nextBidAmount) % parseInt(listingObject.lowerThresholdIncrementMultiplier)))){
                                errorCode = "MAX_ACTIVEBID_AMOUNT_ERROR";
                                var upperMaxBidAmount = (parseInt(listingObject.upperThresholdAmount) + (parseInt(listingObject.nextBidAmount) % parseInt(listingObject.lowerThresholdIncrementMultiplier)))
                                errorMessageAmount = self.formatMoney(upperMaxBidAmount);
                                actionObject = {'minus':'enabled','plus':'disabled'};
                            } else if(parseInt(listingObject.currentHighestBid) > parseInt(listingObject.upperThresholdAmount) && bidval >=  (highSlabAmount + (parseInt(listingObject.upperThresholdIncrementMultiplier) * parseInt(saveListDrawerContent.placeBidContent.bidConfig.highTreshInc)))){
                                errorCode = "MAX_ACTIVEBID_AMOUNT_ERROR";
                                var upperMaxBidAmount = parseInt(listingObject.currentHighestBid) + (parseInt(listingObject.upperThresholdIncrementMultiplier) * parseInt(saveListDrawerContent.placeBidContent.bidConfig.highTreshInc));
                                errorMessageAmount = self.formatMoney(upperMaxBidAmount);
                                actionObject = {'minus':'enabled','plus':'disabled'};
                            }

                            if((parseInt(listingObject.nextBidAmount) <= parseInt(listingObject.lowerThresholdAmount)) && bidval >= parseInt(listingObject.lowerThresholdAmount)){
                                actionObject = {'minus':'enabled','plus':'disabled'};
                            }
                        }


                        // if((bidval >= parseInt(listingObject.lowerThresholdAmount) && bidval <= parseInt(listingObject.upperThresholdAmount) && (bidval - parseInt(listingObject.minBidAmount)) / listingObject.lowerThresholdIncrementMultiplier != 0)){
                        //     errorCode = "LOWER_BID_INCREMENT";
                        //     errorMessageAmount = self.formatMoney(listingObject.lowerThresholdIncrementMultiplier);
                        //     actionObject = {'plus':'enabled','minus':'enabled'};
                        // } else if( (bidval >= parseInt(listingObject.upperThresholdAmount)  && (bidval - parseInt( listingObject.minBidAmount)) / listingObject.upperThresholdIncrementMultiplier != 0)){
                        //     errorCode = "UPPER_BID_INCREMENT";
                        //     errorMessageAmount = self.formatMoney(listingObject.upperThresholdIncrementMultiplier);
                        //     actionObject = {'plus':'enabled','minus':'enabled'};
                        // } else if((bidval-listingObject.minBidAmount) % listingObject.bidIncrement !== 0) {
                        //     errorCode = "MIN_BID_INCREMENT";
                        //     errorMessageAmount = self.formatMoney(listingObject.bidIncrement);
                        //     actionObject = {'plus':'enabled','minus':'enabled'};
                        // }
                        if(bidval >= (parseInt(listingObject.lowerThresholdAmount) + (parseInt(listingObject.minBidAmount) % parseInt(listingObject.lowerThresholdIncrementMultiplier)))){
                            setTimeout(function(){
                                mainContainer.find(messageContainer).find(".h-auto-bid-details").hide();
                            }, 0);
                        } else if(bidval >= (parseInt(listingObject.nextBidAmount))){
                            setTimeout(function(){
                                mainContainer.find(messageContainer).find(".h-auto-bid-details").show();
                            }, 0);
                        }
                    } else{
                       
                        if(listingObject.bidIncrementMultiplier !== 0 ) {

                            if((bidval-listingObject.minBidAmount) % listingObject.bidIncrementMultiplier !== 0) {
                                errorCode = "MIN_BID_INCREMENT";
                                errorMessageAmount = self.formatMoney(listingObject.bidIncrementMultiplier);
                                actionObject = {'plus':'enabled','minus':'enabled'};
                            }

                        } else {

                            if((bidval-listingObject.minBidAmount) % listingObject.bidIncrement !== 0) {
                                errorCode = "MIN_BID_INCREMENT";
                                errorMessageAmount = self.formatMoney(listingObject.bidIncrement);
                                actionObject = {'plus':'enabled','minus':'enabled'};
                            }

                        }
                    }
                    

                }

                // Get error message from CMS 
                errorMessage = saveListDrawerContent.placeBidContent.placeBidError[errorCode] || '';

                // Replace amount with placeholder
                // placeholder example - ###MAX_BID_AMOUNT###, ###MIN_BID_AMOUNT###
                errorMessage = (errorMessageAmount && errorMessage) ? errorMessage.replace('###' + errorCode + '###', errorMessageAmount) : errorMessage;

                // enable/disable plus/minus buttons
                self.enableDisableButtons({ actionObject: actionObject, messageId: messageId });

                if (errorMessage !== '') {
                    $('.ga-error-' + messageId).html(errorMessage).slideDown();
                    return false;
                } else {
                    $('.ga-error-' + messageId).html(" ").slideUp();
                    $(".bid-val-" + messageId).val("$" + self.formatMoney(bidval));
                    return true;
                }
            } catch(e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:placeBidValidation|" + e, null, 1);
            }
        },
        confirmBidAfterSignin: function(userObject, viewObject) {
            try{
                viewObject.userDetails = viewObject.setUserDetails();
                GAUtils.eventTracking.trackevent("Global Alerts", "Global alerts - After sign in Confirm bid button clicked", viewObject.userDetails + " | visitorId:"+ viewObject.visitorId +  "| PageName:"+this.pageName);
                var tupleContainer = viewObject.isMobile ? $('.GACMobile .tuple-container') : $('.GACDesktop .tuple-container');
                tupleContainer.find('.confirm-bid-' + viewObject.messageId).trigger('click');   
                viewObject.messageId = null;
            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:confirmBidAfterSignin|" + exception, null, 1);
            }
        },
        hideConfirmDetails: function(e) {
            try {
                var messageId = e.target && $(e.target).attr("data-message") || null,
                    listingId = e.target && $(e.target).attr("data-listingId") || null;
                
                GAUtils.eventTracking.trackevent("Global Alerts", "Global Alerts - Close confirmation pane icon clicked ", this.userDetails + " | visitorId: "+this.visitorId + " | listingId: "+listingId+" | messageId: " + messageId +  "| PageName:"+this.pageName);
                
                if(messageId) {
                    $(".loader-inner-" + messageId).fadeOut("slow");
                    this.hideConfirmDetailsView({
                        viewObject: this,
                        messageId: messageId,
                        eventObject: e
                    })
                } else {
                    // no message id - In-consistant data
                    GAUtils.eventTracking.trackevent("Global Alerts", "Global Alerts - Close confirmation pane icon clicked - no messageId ", this.userDetails + " | visitorId: "+this.visitorId+ "| PageName:"+this.pageName);
                }

            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:hideViewDetails|" + exception, null, 1);
            }
        },
        hideConfirmDetailsView: function(options) {
            try {
                var self = options.viewObject || this,
                    messageId = options.messageId,
                    messageContainer = $("." + messageId),
                    mainContainer = self.isMobile ? $('.GACMobile .tuple-container') : $('.GACDesktop .tuple-container'),
                    callback = options.callback || null,
                    hideTuple = options.hideTuple || 'N';

                this.userDetails = this.setUserDetails();

                mainContainer.find(messageContainer).removeClass("tuple-expand-bg");
                mainContainer.find(messageContainer).find(".hide-details").show();
                mainContainer.find(messageContainer).find(".close-details").addClass("hide");

                $('.' + messageId + " .ga-bid-confirm-msg-container").addClass("hide");
                $('.confirm-bid-' + messageId).css({ "pointer-events": "all", "opacity": "1" });

                // remove validation error messages
                $('.ga-error-' + messageId).html(" ").slideUp("slow");

                // Hide loader if any.
                $(".loader-inner-" + messageId).hide();

                //remove backupbid title
                if(this.backupBidAlert){
                    this.backupBidAlert = false;
                }
                
                if(hideTuple === 'Y') {
                
                    $('.bemail-' + messageId).hide();
                    mainContainer.find(messageContainer).slideUp();
                    mainContainer.find(messageContainer).find(".confirm-bid-expand").hide()
                    mainContainer.find(messageContainer).find(".ga-property-address").show("fade"); 
                    mainContainer.find(messageContainer).find(".ga-ownitnow-container").show(); 
                    mainContainer.find(messageContainer).find(".h-auto-bid-details").hide();
                    mainContainer.find(messageContainer).find(".new-confirm-edit").hide();
                } else {

                    mainContainer.find(messageContainer).find(".confirm-bid-expand")
                    .hide( "slide", {direction: "right" }, 400, function() {
                        mainContainer.find(messageContainer).find(".ga-property-address").show("fade"); //("slow", function(){});    
                        mainContainer.find(messageContainer).find(".ga-ownitnow-container").show(); //("slow", function(){});
                        if(callback){ callback(); }
                    });
                    mainContainer.find(messageContainer).find(".h-auto-bid-details")
                    .hide( "slide", {direction: "right" }, 400, function() {
                    });
                    mainContainer.find(messageContainer).find(".new-confirm-edit")
                    .hide( "slide", {direction: "right" }, 400, function() {
                    });
                }   
                
            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:hideConfirmDetailsView|" + exception, null, 1);
            }
        },
        goToBidWizard: function(e){
            try {

                e = e || {};

                var self =  this, 
                    messageId = e.target && $(e.target).attr("data-message") || e.messageId,
                    listingId = e.target && $(e.target).attr("data-listingId") || e.listingId,
                    targetPath = contextPath + '/portal/bid?ref=globalalts',
                    toupleContainer = self.isMobile ? $('.GACMobile .tuple-container') : $('.GACDesktop .tuple-container');

                GAUtils.eventTracking.trackevent("Global Alerts", "Global alerts - edit other bid details clicked ",  self.userDetails + " | visitorId:"+ self.visitorId + " | listingId: " + listingId + " | messageId: " + messageId+  "| PageName:"+this.pageName);

                toupleContainer.find(" .bid-form-"+messageId).attr("action", targetPath);
                toupleContainer.find(" .bid-form-"+messageId).submit();
                   
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:goToBidWizard|" + e, null, 1);
            }
        },
        placeBid: function (e) {
            try {
                
                // $('.loader-inner').fadeIn('slow');  remove loader for place bid button  
                

                var self = globalAlertView || this,
                    messageId = $(e.target).attr("data-message"),
                    backupBidType = $(e.target).attr("data-bidtype"),
                    tupleContainer = self.isMobile ? $('.GACMobile') : $('.GACDesktop'),
                    bidAmount,
                    listingId,
                    inputJson,
                    ownItNowAmount,
                    buyerEmailId = '',
                    options = {},
                    bidType,
                    noBuyersPremiumStatus,
                    isActiveBidRefinementEnabled,
                    bidSubmitterRole;

                $.get(contextPath + "/portal/is-valid-session", function (response) {
                        if(response.isValidSession === 'N'){
                         loginRegisterView.openLoginPopup({
                             callback: self.placeBidAfterSignin,
                             viewObject: self,
                             statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessageForPlaceBid
                         });   
                        }
                });

                if(backupBidType && backupBidType == 'DRIVEN_BID_WATCHER_ALERT'){
                    this.userDetails = this.setUserDetails();
                    var listingUrl = $(e.target).attr("data-listingurl"),
                    listingId = $(e.target).attr("data-listingid");
                    GAUtils.eventTracking.trackevent("Global Alerts", "View PDP click", self.userDetails + "| listing ID:"+ listingId + " | visitorId:"+ self.visitorId +"| Notification: Bidding Started"+  "| PageName:"+self.pageName);
                    window.open(listingUrl,"_self");
                    return;
                }
                var callbackOptions = {
                    openPhoneVerification: true,
                    fromGlobalAlert: true,
                    callBackFunc: self.placeBid,
                    placeBidEvent : e
                }
               
                if(typeof mobileVerificationView.mobileVerificationCheckVal != 'undefined' && mobileVerificationView.mobileVerificationCheckVal == 'Y' && !mobileVerificationView.fromMobileVerification){
                    mobileVerificationView.checkMobileVerificationRequired(callbackOptions);
                    return;
                  }
                    if(backupBidType && backupBidType == 'BACKUPBID'){
                        self.backupBidExpiryAlert = false;
                        self.backupBidAlert = true;
                        self.propsBackupEnabled = true;
                    } else if(backupBidType && backupBidType == 'BACKUP_BID_EXPIRY'){
                        self.backupBidExpiryAlert = true;
                        self.backupBidAlert = false;
                    } else {
                        self.backupBidAlert = false;
                        self.propsBackupEnabled = false;
                    }
                noBuyersPremiumStatus = tupleContainer.find('#' + messageId).attr("data-no-buyers-premium") || 'N';
                isActiveBidRefinementEnabled = tupleContainer.find('#' + messageId).attr("data-isactivebidrefinementenabled") || 'N';
                listingId = tupleContainer.find('#' + messageId).attr("data-listing-id");
                bidAmount = tupleContainer.find('.ga-property-address').find(".bid-val-" + messageId).val();
                bidAmount = bidAmount ? bidAmount.replace(/[\$,]/g, '') : '';

                // Log GA event based on action
                ownItNowAmount = $(e.target).attr("data-ownitnow-amount") || null;

                // If any validation errors, exit 
                if(!self.backupBidAlert && !self.backupBidExpiryAlert){
                    if(!ownItNowAmount && isActiveBidRefinementEnabled != 'Y' && !self.placeBidValidation({bidAmount: bidAmount, messageId: messageId})) { 
                        return; 
                    }
                } else {
                    $('.bid-val-'+messageId).val('');
                    
                    bidAmount = self.backupBidAlert ? $('.'+messageId).attr('data-min-bid') : $('.'+messageId).attr('data-last-bid');
                }

                // clear error messages upon success 
                $('.ga-error-' + messageId).html("").slideUp();

                this.userDetails = self.setUserDetails();
                
                // disable "placebid" button
                $(e.target).css({ "pointer-events": "none", "opacity": "0.5" });

                // buyerEmailId = tupleContainer.find('.bemail-' + messageId).text() || '';
                
                bidSubmitterRole = tupleContainer.find('#' + messageId).attr("data-bid-submitter-role") || '';

                if(bidSubmitterRole && bidSubmitterRole == 'AGENT'){
                    buyerEmailId = tupleContainer.find('#' + messageId).attr("data-buyer-email");
                }

                bidAmount = ownItNowAmount ? ownItNowAmount : bidAmount;
                bidType = ownItNowAmount ? "OWN_IT_NOW" : (backupBidType && (backupBidType == 'BACKUPBID' || backupBidType == 'BACKUP_BID_EXPIRY')) ? "PLACE_BACKUP_BID" : "PLACE_BID";
                var url = contextPath + '/portal/v2.0/getPreviousBidDetail';
                
                Utils.fetch({
                    type: "POST",
                    url: url,
                    viewObject: self,
                    eventObject: e,
                    noBuyersPremiumStatus: noBuyersPremiumStatus,
                    ownItNowAmount: ownItNowAmount,
                    callback: self.openConfirmBidView,
                    data: {
                        "bidType" : bidType,
                        "bidAmount" : parseInt(bidAmount.replace(/[\$,]/g,'')),
                        "listingId" : listingId,
                        "buyerEmail" : buyerEmailId
                    }
                });

            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:placeBid|" + exception, null, 1);
            }
        },
        placeBidAfterSignin: function(userObject, viewObject) {
            try{
                viewObject.userDetails = viewObject.setUserDetails();
                GAUtils.eventTracking.trackevent("Global Alerts", "Global alerts - After sign in Place bid button clicked", viewObject.userDetails + " | visitorId:"+ viewObject.visitorId +  "| PageName:"+this.pageName);
                var tupleContainer = viewObject.isMobile ? $('.GACMobile .tuple-container') : $('.GACDesktop .tuple-container');
                tupleContainer.find('.place-bid-' + viewObject.messageId).trigger('click');   
                viewObject.messageId = null;
            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:placeBidAfterSignin|" + exception, null, 1);
            }
        },
        getPreviousBidDetails: function(options) {
            try {
                options = options || {};

                var url = contextPath + '/portal/v2.0/getPreviousBidDetail',
                    inputData = options.inputData || {},
                    self = options.viewObject || this,
                    mainContainer = options.mainContainer,
                    currentBidAmountDetail = options.currentBidAmountDetail || {},
                    currentTupleObject = options.currentTupleObject;                    

                Utils.fetch({
                    type: "POST",
                    url: url,
                    viewObject: self,
                    data: {
                        "bidType" : inputData.bidType,
                        "bidAmount" : inputData.bidAmount,
                        "listingId" : inputData.listingId,
                        "buyerEmail" : inputData.buyerEmailId
                    },
                    callback: function(responseData) {
                     
                        var response = responseData.response || {},
                            apiStatus = responseData.status || 'ERROR',
                            status = response.status || 'ERROR';
                    
                        if (status === "SUCCESS") {

                            // Update bid model 
                            bidWizardModel.attributes = $.extend({}, response.data);
                            bidWizardModel.attributes.bidId = null;
                            if(bidWizardModel.attributes.autoBidAmountDetail === null) {
                                bidWizardModel.attributes.autoBidAmountDetail = {};
                            }

                            // Update bid model with current bid details 
                            bidWizardModel.attributes.bidAmountDetail.bidAmount = currentBidAmountDetail.bidAmount;
                            bidWizardModel.attributes.bidAmountDetail.buyerPremiumAmount = currentBidAmountDetail.buyerPremiumAmount;
                            bidWizardModel.attributes.bidAmountDetail.emdAmount = currentBidAmountDetail.emdAmount;
                            
                           // bidWizardModel.attributes.bidType = "PLACE_BID";
                           bidWizardModel.attributes.bidType = currentBidAmountDetail.bidType;

                            // TODO: this has to be change
                            bidWizardModel.attributes.buyerEmail = ( userSession.userType === "BUYR" ) ? '' : bidWizardModel.attributes.buyerEmail;

                            // update finance Type to tuple 
                            currentTupleObject.attr('data-finance-type', response.data.finacingDetail.paymentType);

                        }

                        // enable action CTAs
                        mainContainer.find(currentTupleObject).find(".ga-confirm-bid, .ga-bid-plus-minus").css({ "pointer-events": "all", "opacity": "1" });

                    }
                });

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:getPreviousBidDetails|" + error, null, 1);
            }  
        },
        openConfirmBidView: function(responseData) {

            try {

                responseData = responseData || {};
                
                var response = responseData.response || {},
                    options = responseData.options || {},
                    inputData = options.data || {},
                    apiStatus = responseData.status || 'ERROR',
                    status = response.status || 'ERROR',
                    ownItNowAmount = options.ownItNowAmount || null,
                    noBuyersPremiumStatus = options.noBuyersPremiumStatus || 'N',
                    self = options.viewObject || this,
                    eventObject = options.eventObject || null,
                    messageId = $(eventObject.target).attr("data-message"),
                    currentTupleObject = $("." + messageId),                
                    listingId = inputData.listingId || '',
                    bidType = inputData.bidType || 'PLACE_BID',
                    mainContainer = self.isMobile ? $('.GACMobile .tuple-container') : $('.GACDesktop .tuple-container'),
                    mainContainerDiv = self.isMobile ? ".GACMobile" : ".GACDesktop",
                    erroCode,
                    buttonLabel,
                    errorMessage, contingenciesData = {};

                if ( status === "SESSION_EXPIRED") {

                    self.sessionRequiredForValidation = true; 

                    self.messageId = messageId;
                    loginRegisterView.openLoginPopup({
                        callback: self.placeBidAfterSignin,
                        viewObject: self,
                        statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessage
                    });                                
                    return;
                }

                
                    
                // TODO: bid deposit handlingx  
                erroCode = response.errorCode || '';
                if ( status == "FAILURE" && erroCode === "BID_DEPOSIT_REQUIRED"){
                    GAUtils.eventTracking.trackevent("Global Alerts", "Bid deposit required - redirecting to bid wizard", " userId: " + userSession.userId + " | listingId: " + listingId +  "| PageName:"+self.pageName);
                    
                    errorMessage = saveListDrawerContent.globalAlerts.bidErrorMessages["BID_DEPOSIT_REQUIRED"] || "You are redirecting to bid wizard";
                    errorMessage = errorMessage.replace("###AMOUNT###", '$1000')
                    $('.' + messageId + " .ga-bid-confirm-msg-container").removeClass("hide").addClass("info").find(".ga-bid-confirm-msg").html(errorMessage).show("slow");        

                    self.goToBidWizard({
                        listingId: listingId,
                        messageId: messageId
                    })
                    return;
                }

                if ( apiStatus !== "SUCCESS" || status !== "SUCCESS") {
                    // TODO: ERROR MESSAGE
                    errorMessage = saveListDrawerContent.globalAlerts.bidErrorMessages["DEFAULT"] || "Looks like something is a miss, please bid again";
                    $('.' + messageId + " .ga-bid-confirm-msg-container").removeClass("hide").find(".ga-bid-confirm-msg").html(errorMessage).show("slow");        
                    return;
                }

                // Update bid model 
                bidWizardModel.attributes = $.extend({}, response.data);
                bidWizardModel.attributes.bidId = null;
                if(bidWizardModel.attributes.autoBidAmountDetail === null) {
                    bidWizardModel.attributes.autoBidAmountDetail = {};
                }

                if(self.backupBidAlert || self.backupBidExpiryAlert){
                    bidWizardModel.attributes.bidType = "PLACE_BACKUP_BID";
                    bidWizardModel.attributes.isUpdateExpiryRequest = true;
                    if(bidWizardModel.attributes.premiumPaidBy && bidWizardModel.attributes.premiumPaidBy == "SELLER"){
                        $('.' + listingId + ' .buyer-premium-container').addClass('hide');
                        $('.b-auto-bid-bp-value').addClass('hide'); // auto bid section
                        $('.'+ listingId +' .backup-no-buyers-premium-msg').html(_.escape(saveListDrawerContent.globalAlerts.labels.sellerPremiumText));
                    }else {
                        $('.b-auto-bid-bp-value').removeClass('hide'); // auto bid section
                    }
                } else {
                    bidWizardModel.attributes.bidType = bidType;
                    bidWizardModel.attributes.isUpdateExpiryRequest = false;
                    if(bidWizardModel.attributes.premiumPaidBy && bidWizardModel.attributes.premiumPaidBy == "SELLER"){
                        $('.' + listingId + ' .buyer-premium-container').addClass('hide');
                        $('.b-auto-bid-bp-value').addClass('hide'); // auto bid section
                        $('.'+ listingId +' .backup-no-buyers-premium-msg').html(_.escape(saveListDrawerContent.globalAlerts.labels.sellerPremiumText));
                    }else {
                        $('.b-auto-bid-bp-value').removeClass('hide'); // auto bid section
                    }
                }

                self.bidSubmitterRole = response.data && response.data.bidSubmitterRole || undefined;
                // if (response.data && response.data.bidSubmitterRole && response.data.bidSubmitterRole === "AGENT" && userSession.userType === "AGENT" ) {
                if (response.data && response.data.bidSubmitterRole && response.data.bidSubmitterRole === "AGENT") {
                    bidWizardModel.attributes.buyerEmail = response.data.buyerEmail;
                } else {
                    bidWizardModel.attributes.buyerEmail = "";
                }

                // update finance Type to tuple 
                currentTupleObject.attr('data-finance-type', response.data.finacingDetail.paymentType);

                // Get contingencies list 
                self.getContingencies({
                    listingId: listingId,
                    paymentType: (response.data.finacingDetail.paymentType == 'MORTGAGE') ? 'MORTGAGE' : 'CASH',
                    bidAmount: inputData.bidAmount
                });

                // TODO: in new API, we don't have listingStatus - resolve it another way.
                // End the auction now is clicked when the listing is closed - show the expired tuple 
                // if(ownItNowAmount && responseData.listingStatus &&  responseData.listingStatus === 'CLOSED') {
                //     $('.alert-expiry-status-' + messageId).removeClass('hide');   
                //     return;
                // }

                buttonLabel = ownItNowAmount ? "Own it now- AUCN" : "Place Bid";
                if(self.backupBidAlert || self.backupBidExpiryAlert){
                    var actionLabel = self.backupBidAlert ? "Place backup bid click" : "Update backup bid time limit click"
                    GAUtils.eventTracking.trackevent("Global Alerts", actionLabel, self.userDetails + " | visitorId:"+ self.visitorId +  "| PageName:"+self.pageName);
                } else {
                    GAUtils.eventTracking.trackevent("Global Alerts", buttonLabel, " userId: " + userSession.userId + " | listingId: " + listingId  +  "| PageName:"+self.pageName);
                }


                // enable confirm buttons if any disabled
                $('.ga-confirm-bid').css({ "pointer-events": "all", "opacity": "1" });

                // Hide error messages if any
                $(".ga-bid-confirm-msg-container").addClass('hide');

                // remove success pane if exists
                $(".bid-success-container").slideUp();

                // enable "placebid" button 
                $(eventObject.target).css({ "pointer-events": "all", "opacity": "1" });

                // remove color background and 
                mainContainer.find('.ga-tuple-item').removeClass("tuple-expand-bg");
                mainContainer.find(".hide-details").show();

                // show addresses if any hidden while opening confirm window
                mainContainer.find(".ga-property-address").show(); 
                mainContainer.find(".ga-ownitnow-container").show(); 
                mainContainer.find(".close-details").addClass("hide");
                
                // collapse other tuple's view deatils    
                mainContainer.find(".tuple-data-expand").hide(); //.slideUp(function(){
                mainContainer.find(".confirm-bid-expand").hide();
                mainContainer.find(".h-auto-bid-details").hide();
                mainContainer.find(".new-confirm-edit").hide();
                
                mainContainer.find(currentTupleObject).find(".ga-ownitnow-container").hide();    
                mainContainer.find(currentTupleObject).find(".ga-property-address").hide();    

                // Update bidType in hidden form (which will open bid wizard)
                $('.hidden-bid-form-bidtype-' + messageId).val(bidType);
                $(".bid-form-" + messageId + " .bidValue" + messageId).val(inputData.bidAmount);

                // Check if it available for own it now
                if(ownItNowAmount){
                    
                    // Reason behind this hide/show of prices, we are using same pane for both scenarios
                    // Disable normal bid price
                    $('.' + listingId + ' .normal-price').addClass('hide');
                    $('.' + listingId + ' .bid-amount-label').addClass('hide');

                    // Enable own it now amount. 
                    $('.' + listingId + ' .own-itnow-price').removeClass('hide');
                    $('.' + listingId + ' .own-itnow-label').removeClass('hide');

                    // Update own it now amount
                    ownItNowAmount = Number(ownItNowAmount);
                    $('.' + listingId + ' .own-itnow-price .ownitnow-total').text("$" + self.formatMoney(ownItNowAmount));
                    
                    // add data attribute to confirm bid button to differentiate the Place bid/Own it now
                    $('.' + listingId + ' .confirm-bid-' + messageId).attr("data-ownitnow-clicked", "Y");
                    $('.' + listingId + ' .confirm-bid-' + messageId).attr("data-ownitnow-amount", ownItNowAmount);
                    
                    
                } else {
                    $('.' + listingId + ' .normal-price').removeClass('hide');
                    $('.' + listingId + ' .bid-amount-label').removeClass('hide');
                    $('.' + listingId + ' .own-itnow-price').addClass('hide');
                    $('.' + listingId + ' .own-itnow-label').addClass('hide');
                    $('.' + listingId + ' .confirm-bid-' + messageId).attr("data-ownitnow-clicked", "N");   
                }

                // Add color background
                mainContainer.find(currentTupleObject).addClass("tuple-expand-bg");

                // check technology fee is available for this auction or not
                mainContainer.find(currentTupleObject).find('.technology-fee-container').hide();

                // Disable action CTAs while fetching details 
                mainContainer.find(currentTupleObject).find(".ga-confirm-bid, .ga-bid-plus-minus").css({ "pointer-events": "none", "opacity": "0.5" });
                
                if(self.backupBidAlert && self.propsBackupEnabled){
                    self.getBackupBidDetails({bidAmount: 0, messageId: messageId, listingId: listingId, disableValidation:true });
                } else {
                    // Update total amount due
                    mainContainer.find(currentTupleObject).find(".ga-bid-totalDue").text(inputData.bidAmount);
                    var callbackObj = {bidAmount: inputData.bidAmount, messageId: messageId, listingId: listingId, ownItNowAmount: ownItNowAmount};
                    if(self.backupBidExpiryAlert || ownItNowAmount){
                        callbackObj.disableValidation = true;
                    }
                    self.calculateBuyersPremium(callbackObj);
                }
                
                // Show confirmation pane
                mainContainer.find(currentTupleObject).find(".confirm-bid-expand")
                .show( "slide", {direction: "right" }, 400, function() {
                    var target = $(mainContainerDiv + " ." + messageId ) || null, topOffset;
                    topOffset = target && target.offset().top || null;
                    
                    if(newSkinning && window.screen.width < 768){
                        
                    }else{
                        if(target && topOffset){
                            mainContainer.animate({ scrollTop: topOffset - 30});     
                        }    
                    }
                    
                });

                // mainContainer.find(currentTupleObject).find(".h-auto-bid-details")
                // .show( "slide", {direction: "right" }, 400, function() {
                //     var target = $(mainContainerDiv + " ." + messageId ) || null, topOffset;
                //     topOffset = target && target.offset().top || null;
                //     if(newSkinning && window.screen.width < 768){
                        
                //     }else{
                //         if(target && topOffset){
                //             mainContainer.animate({ scrollTop: topOffset - 30});     
                //         }    
                //     }
                // });

               // if(bidWizardModel.attributes.bidAmountDetail.isActiveBidRefinementEnabled && bidWizardModel.attributes.bidAmountDetail.isActiveBidRefinementEnabled === 'Y' && inputData.bidAmount && inputData.bidAmount >= bidWizardModel.attributes.bidAmountDetail.lowerThresholdAmount){
                if(ownItNowAmount){
                    mainContainer.find(currentTupleObject).find(".h-auto-bid-details").hide();

                } else {
                    mainContainer.find(currentTupleObject).find(".h-auto-bid-details")
                    .show( "slide", {direction: "right" }, 400, function() {
                        var target = $(mainContainerDiv + " ." + messageId ) || null, topOffset;
                        topOffset = target && target.offset().top || null;
                        if(newSkinning && window.screen.width < 768){
                            
                        }else{
                            if(target && topOffset){
                                mainContainer.animate({ scrollTop: topOffset - 30});     
                            }    
                        }
                    });
                }

                mainContainer.find(currentTupleObject).find(".new-confirm-edit")
                .show( "slide", {direction: "right" }, 400, function() {
                    var target = $(mainContainerDiv + " ." + messageId ) || null, topOffset;
                    topOffset = target && target.offset().top || null;
                    if(newSkinning && window.screen.width < 768){
                        
                    }else{
                        if(target && topOffset){
                            mainContainer.animate({ scrollTop: topOffset - 30});     
                        }    
                    }
                });

                //Auto bid section logic
                if(response.data.isAutoBidSelected){
                    $("#enable-auto-bid-"+messageId).attr('checked','checked');
                    $("#autoBidSectionGA-"+messageId).show();
                    $("#b_autobid_amount-"+messageId).val(response.data.autoBidAmountDetail.bidAmountFormatted);
                    $("#b_autobid_amount-"+messageId).attr('data-auto-bid',response.data.autoBidAmountDetail.bidAmount);
                    $("#b_autobid_amount-"+messageId).attr('data-amt',response.data.autoBidAmountDetail.bidAmount);
                    if(response.data.autoBidAmountDetail.buyerPremiumPercentage !== null) {
                        $("#autoBidBPPerc-"+messageId).html(" ("+response.data.autoBidAmountDetail.buyerPremiumPercentage+"%)");
                    } else {
                        $("#autoBidBPPerc-"+messageId).html();
                    }
                    
                    $("#b-autobid-bp-text-"+messageId).html(response.data.autoBidAmountDetail.buyerPremiumFormatted);
                    $("#b-autobid-total-text-"+messageId).html(response.data.autoBidAmountDetail.totalDueFormatted);
                }else{
                    $("#enable-auto-bid-"+messageId).prop('checked', false);
                    $("#autoBidSectionGA-"+messageId).hide();
                    $("#b_autobid_amount-"+messageId).val('');
                    $("#b_autobid_amount-"+messageId).attr('data-auto-bid','');
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:openConfirmBidView|" + e, null, 1);
            }
        },

        getContingencies: function(params) {
            try {

                var self = this,
                    bidValue = Number(params.bidAmount || ''),
                    listingId = params.listingId || '',
                    paymentType = params.paymentType || '';

                if ( !bidValue || !listingId || !paymentType ) return;

                Utils.fetch({
                    type: "GET",
                    url: contextPath + '/portal/api/bidContigency/' + listingId + '/' + paymentType + '/' + bidValue,
                    viewObject: self,
                    callback: function(responseData) {

                        if ( responseData.status === "SUCCESS" && responseData.response && responseData.response.length ) {
                            bidWizardModel.attributes.finacingDetail.bidContingencyMasterList = responseData.response;
                        }
                    }
                });
                
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:getContingencies|" + error, null, 1);
            }
        },

        confirmBid: function(e){
            try {
                
                var self = this,
                    messageId = $(e.target).attr("data-message"),
                    tupleContainer = self.isMobile ? $('.GACMobile') : $('.GACDesktop'),
                    mainContainer = self.isMobile ? $('.GACMobile .tuple-container') : $('.GACDesktop .tuple-container'),
                    bidAmount,
                    listingId,
                    buyerEmail,
                    confirmBidButtonElement,
                    isOwnitNow,
                    bidType = ($(e.target).data('bidtype') && (self.backupBidAlert || self.backupBidExpiryAlert)) ? "PLACE_BACKUP_BID" : "PLACE_BID",
                    options = {};
                
                self.userDetails = self.setUserDetails();
                
                self.sessionRequiredForValidation = false; 

                listingId = tupleContainer.find('#' + messageId).attr("data-listing-id");
                if(self.backupBidExpiryAlert){
                    bidAmount = (bidWizardModel.attributes && bidWizardModel.attributes.bidAmountDetail &&  bidWizardModel.attributes.bidAmountDetail.bidAmount) ?  bidWizardModel.attributes.bidAmountDetail.bidAmount :'';
                } else {
                    bidAmount = tupleContainer.find('.confirm-bid-expand').find(".bid-val-" + messageId).val().replace(/[\$,]/g, '') || '';
                    bidAmount = bidAmount.replace('$','').replace(/,/g,'');
                }
                

                if(self.backupBidAlert){
                    var backupErrorCode = $('.'+messageId+' .ga-error-bid').html();
                    if(bidAmount == '' || backupErrorCode){
                        bidAmount == '' ? $('.'+listingId+' .ga-error-bid').html(saveListDrawerContent.backupBidContent.error_code['empty_mess']).slideDown() : '';
                        return;
                    }
                }

                if(self.backupBidExpiryAlert){
                    var selectedVal = $('.'+listingId).find('.h-select-box').val();
                    if(selectedVal){
                        bidWizardModel.attributes.backupBidDaysSelected = selectedVal;
                    } else {
                        $('.'+listingId+' .backupBidDayError').slideDown();
                        return;
                    }
                }
                if(self.backupBidAlert || self.backupBidExpiryAlert){
                    var bidTypeLabel = self.backupBidAlert ? "Bid type:Backup bid" : "";
                    var actionLabel = self.backupBidAlert ? "Place Bid" : "Submit time limit update click";
                    GAUtils.eventTracking.trackevent("Global Alerts", actionLabel,  this.userDetails+ " | visitorId:"+ this.visitorId + " | "+bidTypeLabel);
                } else {
                    GAUtils.eventTracking.trackevent("", "Global Alerts - Confirm bid button clicked",  this.userDetails+ " | visitorId:"+ this.visitorId + " | listingId: " + listingId + " | messageId: " + messageId);
                }
                


                GAUtils.eventTracking.trackevent("Global Alerts", "Global Alerts - Confirm bid button clicked",  this.userDetails+ " | visitorId:"+ this.visitorId + " | listingId: " + listingId + " | messageId: " + messageId+ "| PageName:"+this.pageName);

                confirmBidButtonElement = mainContainer.find('.' + listingId + ' .confirm-bid-' + messageId);
                isOwnitNow = confirmBidButtonElement.attr("data-ownitnow-clicked") || 'N';

                //check for auto bid
                self.autoBidApplicableGA = false;
                if($('#enable-auto-bid-'+messageId).length) {
                    if(bidWizardModel.attributes.bidAmountDetail.isActiveBidRefinementEnabled === 'Y' && bidWizardModel.attributes.bidAmountDetail.bidAmount >= bidWizardModel.attributes.bidAmountDetail.lowerThresholdAmount && !tupleContainer.find('#' + messageId+' .h-auto-bid-details').is(":visible")){
                        console.log('Break auto bid active bid refinemet');
                    } else {
                        self.autoBidApplicableGA = true;
                        if($('#enable-auto-bid-'+messageId).is(':checked')) {
                            bidWizardModel.attributes.isAutoBidSelected = true;
                            var autoBidAmount  = $("#b_autobid_amount-"+messageId).val().replace(/[\$,]/g, '');
                            autoBidAmount = autoBidAmount.replace('$','').replace(/,/g,'');
                            bidWizardModel.attributes.autoBidAmountDetail.bidAmount = autoBidAmount;
                            $("#b_autobid_amount-"+messageId).blur();
                            self.autoBidSelectedGA = true;
                        }else{
                            bidWizardModel.attributes.isAutoBidSelected = false;
                            bidWizardModel.attributes.autoBidAmountDetail.bidAmount = null;
                            self.autoBidSelectedGA = false;
                        }
                        
                        if($("#b_autobid_amount-"+messageId).hasClass('error')){
                            return;
                        }
                    }
                }

                // Check if own it now clicked
                if (isOwnitNow && isOwnitNow === "Y") {
                    bidAmount = confirmBidButtonElement.attr("data-ownitnow-amount")
                    bidType = "OWN_IT_NOW";
                }

                // If own it now clicked, then don't perform placebid validations 
                // If any validation errors, exit 
                if(!self.backupBidAlert && !self.backupBidExpiryAlert && isOwnitNow !== "Y" && !self.placeBidValidation({bidAmount: bidAmount, messageId: messageId})) { 
                    return; 
                }

                $(e.target).css({ "pointer-events": "none", "opacity": "0.5" });
                $(".loader-inner-"+messageId).fadeIn("slow");
                    
                options.callback = this.confirmBidAfterValidation;
                options.viewObject = this;
                options.messageId = messageId;
                options.listingId = listingId;
                options.bidAmount = bidAmount;
                options.bidType = bidType;
                options.isOwnitNow = isOwnitNow;
                options.userId = window.userSession.userId || null;
                placeBidView.validateBidAmount(options);

            } catch (exception) {
                var option = {};
                option = {"component" : "Submit_Bid_UI", "failJson" : "Source Page: Global alerts | file:alertsView.js | function:placeBid | " + exception };
                monitoringView.logUIErrors(option);
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:confirmBid|" + exception, null, 1);
            }
        },
        /**
         * Shows modal popup with warning.
         * @param {formSelector, amount, source} options 
         *      formSelector    - #testing / .testing
         *      amount          - $2,000
         *      source          - Global Alerts / PDP
         */
        showFradulentErrorModal: function(options) {
            try {
                options = options || {};

                var self = this,
                    content = saveListDrawerContent.globalAlerts.labels || {}, 
                    warningMessage,
                    amount = options.amount || 1000,
                    formSelector = options.formSelector || '',
                    formDocumentSelector = options.documentFormSubmit || '',

                    source = options.source || "Global Alerts";
                
                warningMessage = content.fraudulentErrorContent;
                amount = "$"+amount.formatMoney();
                warningMessage = warningMessage.replace('###AMOUNT###', amount);
                
                GAUtils.eventTracking.trackevent("Global Alerts", "Fraudulent warning modal - Source: " + source ,  self.userDetails+ " | visitorId: "+ self.visitorId +  "| PageName:"+this.pageName);

                $('.modal-popup-container').html(fraudulentErrorModalTemplate({
                    warningMessage: warningMessage,
                    content: content,
                    formSelector: formSelector
                }));

                $('.modal-popup-container .fraudulent-warning-modal').modal('show');

                $(".fraudulent-warning-modal .fb-action button").unbind('click').bind('click', function(){
                    GAUtils.eventTracking.trackevent("Global Alerts", "Fraudulent warning modal - Proceed button click - Source: " + source ,  self.userDetails+ " | visitorId: "+ self.visitorId +  "| PageName:"+this.pageName);
                    if (formSelector != ''){
                        $(formSelector).submit();
                    }else if(formDocumentSelector !=''){
                        formDocumentSelector.submit();
                    }
                });
                
                if (self.isMobile) {
                    $('.modal-popup-container .fraudulent-warning-modal').on("hidden.bs.modal", function(){
                        $(".fraudulent-warning-modal").css("zIndex", "1050");
                        $(".modal-backdrop").css("zIndex", "1040");
                    });
                    $(".fraudulent-warning-modal").css("zIndex", "1051");
                    $(".modal-backdrop").css("zIndex", "1050");
                }
                
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:showFradulentErrorModal|" + error, null, 1);
            }
        },
        confirmBidAfterValidation: function(options) {
            try {

                var response = options.responseData || {},
                    self = options.callbackOptions.viewObject || this,
                    messageId = options.callbackOptions.messageId || null,
                    listingId = options.callbackOptions.listingId || null, 
                    isOwnitNow = options.callbackOptions.isOwnitNow || 'N',
                    status = response.status || 'ERROR',
                    data = response.data || {},
                    errorCode = response.errorMessage || '',
                    errorMessage, databaseUrl;
                    
                if (status === 'SUCCESS' && data.isValid )  {
                    
                    // remove listener on current listing 
                    databaseUrl = 'listings/' + listingId;
                    if(self.tenantId) { 
                        databaseUrl = self.tenantId + "/" + databaseUrl;
                    }
                    self.detachListners({databaseUrls: [databaseUrl] });

                    // keeping messageId in view level for checking in 
                    // removeListing in order to restrict delete li.
                    self.messageId = messageId;
                    self.isBiddingInProgress = true;

                    // reset Auto Bid changes
                    // self.resetAutoBidChanges({bidType: options.callbackOptions.bidType});

                    // invoke submit bid 
                    Utils.fetch({
                        type: "POST",
                        url: contextPath + '/portal/v2.0/submitBid',
                        listingId: listingId,
                        messageId: messageId, 
                        viewObject: self,
                        isOwnitNow: isOwnitNow,
                        data: bidWizardModel.attributes,
                        callback: self.bidPlacedCallback
                    });
                    
                } else {
                    
                    $(".loader-inner-" + messageId).fadeOut("slow");

                    errorCode = data.reasonCode && data.reasonCode || 'GENERIC_ERROR';

                    if( errorCode === 'SESSION_REQUIRED' ) {
                        
                        self.sessionRequiredForValidation = true; 

                        self.messageId = messageId;
                        loginRegisterView.openLoginPopup({
                            callback: self.confirmBidAfterSignin,
                            viewObject: self,
                            statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessage
                        });                                
                    } else if ( errorCode === "FRAUDULENT_BID_DEPOSIT_REQUIRED"){

                        self.showFradulentErrorModal({
                            amount: data.dataMap.FRAUDULENT_BID_DEPOSIT_AMOUNT || '',
                            formSelector: self.isMobile ? '.GACMobile .tuple-container .bid-form-' + messageId : '.GACDesktop .tuple-container .bid-form-' + messageId
                        });

                    } else {
                        errorMessage = saveListDrawerContent.globalAlerts.errorMessages[errorCode] 
                                    || saveListDrawerContent.globalAlerts.errorMessages['GENERIC_ERROR'];

                        errorMessage = errorMessage.replace("###BID_AMOUNT###", bidWizardModel.attributes.bidAmountDetail.bidAmount);
                        $('.' + messageId + " .ga-bid-confirm-msg-container").removeClass("hide").find(".ga-bid-confirm-msg").html(errorMessage).show("slow");        
                    }
                } 
            } catch(e){
                var option = {};
                option = {"component" : "Submit_Bid_UI", "failJson" : "Source Page: Global alerts | file:alertsView.js | function:confirmBidAfterValidation | " + e };
                monitoringView.logUIErrors(option);
            }
        },
        resetAutoBidChanges : function(options){
            try{
                options = options || {};
                BidModel.attributes.bidType = options.bidType || 'PLACE_BID';
                BidModel.attributes.autoBidEnabled = false;
                BidModel.attributes.bidDetail.isAutoBidSelected = false;
                BidModel.attributes.bidDetail.autoBidAmountDetail = null;
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:resetAutoBidChanges|" + e, null, 1);
            }
        },
        bidPlacedCallback : function(responseData) {
        // bidPlacedCallback : function(status, message, options) {
            try{

                var response = responseData.response || {},
                    data = response.data || {},
                    options = responseData.options || {},
                    inputData = options.data || {},
                    self = options.viewObject || null,
                    messageId = options.messageId || null,
                    listingId = options.listingId || null,
                    statusCode,
                    apiStatus = responseData.status || 'ERROR',
                    status = response.status || "FAILURE",
                    isOwnitNow = options.isOwnitNow || 'N',
                    currentBidAmount = inputData.bidAmountDetail.bidAmount || '',
                    globalAlertsCmsNode = saveListDrawerContent.globalAlerts || {},
                    errorMessage,
                    description,
                    bidId,
                    bidTypeLabel = '',
                    timeLimitEvent = false,
                    eventAction = "";


                if (currentBidAmount) currentBidAmount = '$' + Number(currentBidAmount).formatMoney();

                $(".loader-inner-" + messageId).hide();
                
                self.isBiddingInProgress = false;
                self.messageId = null;

                // RESERVE_MET, OUTBID, REJECTED, BID_AUTO_EXTD_MSG
                if(data.statusCodes && data.statusCodes.length ) {
                    statusCode = response.data.statusCodes[0];
                }

                if(self && messageId && apiStatus === "SUCCESS") {

                    if(status === 'SESSION_EXPIRED'){

                        loginRegisterView.openLoginPopup({
                            callback: self.confirmBidAfterSignin,
                            viewObject: self,
                            statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessage
                        });

                    } else if (status === "SUCCESS" ) {

                        try{
                            window.dataLayer = window.dataLayer || [];
                            dataLayer.push({ event: 'bid_completion'}); 
                            dataLayer.push({
                                'transactionId': response.data.bidId,
                                'transactionAffiliation': typeof self.bidSubmitterRole !='undefined'?self.bidSubmitterRole:'',
                                'transactionTotal': response.data.currentHighestBidAmount,
                                'transactionTax': $('.' + messageId).attr("data-buyer-prem-amount"),
                                'transactionShipping': '',
                                'transactionProducts': [{
                                    'sku': $('.' + messageId).attr("data-listing-id"),
                                    'name': $('.' + messageId).attr("data-streetNumber")+', '+$('.' + messageId).attr("data-streetName") +' , '+$('.' + messageId).attr("data-city") +', '+$('.' + messageId).attr("data-county") +' , '+$('.' + messageId).attr("data-streetName") + ',' +$('.' + messageId).attr("data-zip"),
                                    'category': $('.' + messageId).attr("data-prop-category"),
                                    'price': $('.' + messageId).attr("data-start-bid"),
                                    'listingPrice': $('.' + messageId).attr("data-list-price"),
                                    'quantity': 1
                                }]
                                });
                        }catch(e){
                            console.log(e);
                        }
                        if(statusCode === "OUTBID") {
                            
                           /*
                            * i.e user placed bid successfully but got outbid because of autobid by other user
                            */ 
                            $('.confirm-bid-' + messageId).css({ "pointer-events": "all", "opacity": "1" });
                            errorMessage = globalAlertsCmsNode.errorMessages['AUTO_OUTBID_ERROR'] || 'Another buyer already placed a bid for the same amount or greater';
                            errorMessage = errorMessage.replace("###BID_AMOUNT###", currentBidAmount);

                            $('.' + messageId + " .ga-bid-confirm-msg-container").removeClass("hide").find(".ga-bid-confirm-msg").html(errorMessage).slideDown("slow");        

                        } else if (statusCode === "REJECTED"){

                           /*
                            * i.e user placed a bid '$60,000' successfully, 
                            * which is equal to auto bid limit of other user(Auto bid limit - $60,000)
                            */

                            self.hideConfirmDetailsView({
                                messageId: messageId,
                                viewObject: this,
                                hideTuple: 'Y'
                            });
                            
                            //render success pane with animation 
                            description = globalAlertsCmsNode.labels.autobidOutbidErrorMessage;
                            description = description.replace("###BID_AMOUNT###", currentBidAmount);

                            // tupleStatus == Y || N
                            // If we send tuple status as Y, up on closing success template
                            // it will reopen tuple with message ID.
                            $(".bid-success-container").html(bidPlacedSuccessTemplate({
                                globalAlertsCmsNode: globalAlertsCmsNode,
                                description: '',
                                errorMessage: description,
                                messageId: messageId,
                                tupleStatus: 'Y'
                            }));
                            $(".bid-success-container").slideDown();

                        } else {
                            
                            // In case of OWN IT NOW, show different description 
                            if (isOwnitNow === 'Y') {
                                description = globalAlertsCmsNode.labels.successNotificationOwnitNowDesc;
                            } else {

                                if (statusCode === "RESERVE_MET") {
                                    description = globalAlertsCmsNode.labels.reserveMetMessage;
                                } else if (statusCode === "BID_AUTO_EXTD_MSG") {
                                    description = globalAlertsCmsNode.labels.auctionExtendedMessage;
                                } else if(self.backupBidAlert){
                                    description = globalAlertsCmsNode.labels.successNotificationBackupBidDesc;
                                    eventAction = "Global Alerts";
                                    bidTypeLabel = "| Bid type:Backup bid";
                                    self.backupBidAlert = false;
                                    self.removeListing({
                                        viewObject: self,
                                        messageId: messageId
                                    });
                                } else if(self.backupBidExpiryAlert){
                                    description = globalAlertsCmsNode.labels.successNotificationBackupBidExpiryDesc;
                                    self.backupBidExpiryAlert = false;
                                    timeLimitEvent = true;
                                    self.removeListing({
                                        viewObject: self,
                                        messageId: messageId
                                    });
                                } else {
                                    description = globalAlertsCmsNode.labels.successNotificationDesc;
                                }
                                    
                                description = description.replace("###BID_AMOUNT###", currentBidAmount);                             
                            }
                            
                            //render success pane with animation 
                            $(".bid-success-container").html(bidPlacedSuccessTemplate({
                                globalAlertsCmsNode: globalAlertsCmsNode,
                                description: description,
                                errorMessage: '',
                                messageId: messageId,
                                tupleStatus: 'N'
                            }));
                            $(".bid-success-container").slideDown();

                            //empty and hide buyer email id
                            $('.bemail-' + messageId).remove();
                            $('.alert-expiry-status-' + messageId).remove();

                            

                            // scroll to top
                            $('.gobal-alerts-drawer-slider').animate({scrollTop: '0px'}, 400);

                            var autoBidEnabled = "No";
                            if(inputData.isAutoBidSelected)
                            {
                                autoBidEnabled = "Yes";
                            }else{
                                autoBidEnabled = "No";
                            }
                            bidId = data.bidId || null;

                            if(timeLimitEvent){
                                GAUtils.eventTracking.trackevent("Global Alerts", "Backup bid limit updated", "userId: "+ userSession.userId+"| Time limit:"+inputData.backupBidDaysSelected+" Days"+ "|Source: Global Alerts");
                            } else {
                                GAUtils.eventTracking.trackevent(eventAction, "Bid Placed", "userId: "+ userSession.userId + "|listingId: " + listingId + "|bidId: " + bidId + "|userType: "+self.bidSubmitterRole + "|AutoBid Enabled: " + autoBidEnabled + "|IsRepeatBid: Yes|Source: Global Alerts");
                            }
                            
                            
                            // empty and hide alert tuple 
                            $('.' + messageId).slideUp(function(){
                                $(this).remove();
                            });
                            if(self.autoBidApplicableGA) {
                                if(self.autoBidSelectedGA) {
                                    GAUtils.eventTracking.trackevent("Global Alerts", 'Auto-bid enabled', self.userDetails+ " | visitorId:"+ self.visitorId);
                                }else{
                                    GAUtils.eventTracking.trackevent("Global Alerts", 'Auto-bid disabled', self.userDetails+ " | visitorId:"+ self.visitorId);
                                }
                            }
                            // FOR APP - firebase logging
                            if (window.notifyNativeApp) {
                                window.notifyNativeApp(JSON.stringify({
                                    message: "logGaEvent",
                                    category: "event",
                                    action: "Bid Placed",
                                    userId: userSession.userId,
                                    listingId: listingId,
                                    bidId: bidId,
                                    type: "bid",
                                    source: "Global Alerts"
                                }), '*');
                            }
                        }

                    } else if (status === "FAILURE" ){
                    
                        GAUtils.eventTracking.trackevent("Global Alerts","Bid Placed - Error :" + response.errorCode,'userId :'+ userSession.userId+'|'+'listingId:'+ listingId + ' | SRC : NewBidWizard'); 

                        if(response.errorCode == 'BID_DEPOSIT_REQUIRED' || response.errorCode == 'FRAUDULENT_BID') {
                            self.showFradulentErrorModal({
                                amount: response.data.validationResult.bidDepositAmount || '',
                                formSelector: self.isMobile ? '.GACMobile .tuple-container .bid-form-' + messageId : '.GACDesktop .tuple-container .bid-form-' + messageId
                            }); 
                        }

                        response.erroCode = (response.errorCode === '' || response.errorCode === 0) ? 'DEFAULT' : response.errorCode;
                        errorMessage = globalAlertsCmsNode.bidErrorMessages[response.errorCode];

                        $('.' + messageId + " .ga-bid-confirm-msg-container").removeClass("hide").hide().find(".ga-bid-confirm-msg").html(errorMessage);
                        $('.' + messageId + " .ga-bid-confirm-msg-container").slideDown();

                    } else {
                        errorMessage = globalAlertsCmsNode.bidErrorMessages["DEFAULT"];
                        $('.' + messageId + " .ga-bid-confirm-msg-container").removeClass("hide").find(".ga-bid-confirm-msg").html(errorMessage).slideDown("slow");        
                    }

                    // re initiate listener on listing 
                    self.initPropertyListner({
                        viewObject: self,
                        propertyIdList: [{listingId: listingId}],
                        initState: true
                    });

                } else {
                    // go to first pane
                    errorMessage = globalAlertsCmsNode.bidErrorMessages["DEFAULT"];
                    $('.' + messageId + " .ga-bid-confirm-msg-container").removeClass("hide").find(".ga-bid-confirm-msg").html(errorMessage).slideDown("slow");        

                    GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:bidPlacedCallback|messageId is not availabe in place bid callback", null, 1);
                }

            } catch(e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:bidPlacedCallback|" + e, null, 1);
            }
        },


        getListingValues: function (options) {

            try{
                options = options || {};

                var messageId = options.currentBidAmountObject ? options.currentBidAmountObject.attr("data-message") : options.messageId || '',
                    mainContainer = this.isMobile ? $('.GACMobile .tuple-container') : $('.GACDesktop .tuple-container'),
                    currentBidValueObject,
                    tuppleObject,
                    listingObject = {};

                currentBidValueObject = options.currentBidAmountObject ? options.currentBidAmountObject : mainContainer.find(".bid-val-" + messageId);
                tuppleObject = mainContainer.find("." + messageId);

                listingObject = {
                    bidValue: Number(currentBidValueObject.val().replace(/[\$,]/g, '')),
                    bidIncrementMultiplier: Number(tuppleObject.attr("data-bid-min-inc").replace(/[\$,]/g, '')),
                    bidIncrement: Number(tuppleObject.attr("data-bid-inc").replace(/[\$,]/g, '')),
                    // minBidAmount: Number(tuppleObject.attr("data-min-bid").replace(/[\$,]/g, '')) + Number(tuppleObject.attr("data-bid-inc").replace(/[\$,]/g, '')),
                    minBidAmount: Number(tuppleObject.attr("data-min-bid").replace(/[\$,]/g, '')),
                    maxBidAmount: Number(tuppleObject.attr("data-max-bid").replace(/[\$,]/g, '')),
                    currentHighestBid: Number(tuppleObject.attr("data-highest-bid").replace(/[\$,]/g, '')),
                    

                };
                listingObject.isActiveBidRefinementEnabled = tuppleObject.attr("data-isActiveBidRefinementEnabled");
                if(tuppleObject.attr("data-isActiveBidRefinementEnabled") && tuppleObject.attr("data-isActiveBidRefinementEnabled") == 'Y'){
                    listingObject.upperThresholdAmount = tuppleObject.attr("data-upperThresholdAmount");
                    listingObject.upperThresholdIncrementMultiplier = tuppleObject.attr("data-upperThresholdIncrementMultiplier");
                    listingObject.lowerThresholdAmount = tuppleObject.attr("data-lowerThresholdAmount");
                    listingObject.lowerThresholdIncrementMultiplier = tuppleObject.attr("data-lowerThresholdIncrementMultiplier");
                }
                if(tuppleObject.attr("data-is-fc-online") && tuppleObject.attr("data-is-fc-online") == 'Y'){
                    listingObject.isFcOnline = tuppleObject.attr("data-is-fc-online").replace(/[\$,]/g, '');
                    if(tuppleObject.attr("data-reservePrice"))
                    listingObject.reservePrice =  Number(tuppleObject.attr("data-reservePrice").replace(/[\$,]/g, ''));
                    if(tuppleObject.attr("data-bidIncrementPostReserve"))
                    listingObject.bidIncrementPostReserve = Number(tuppleObject.attr("data-bidIncrementPostReserve").replace(/[\$,]/g, ''));
                    if(tuppleObject.attr("data-nextPossibleBidAmountPostReserve"))
                    listingObject.nextPossibleBidAmountPostReserve = Number(tuppleObject.attr("data-nextPossibleBidAmountPostReserve").replace(/[\$,]/g, ''));
                    if(tuppleObject.attr("data-incrementMulitplierPostReserve"))
                    listingObject.incrementMulitplierPostReserve = Number(tuppleObject.attr("data-incrementMulitplierPostReserve").replace(/[\$,]/g, ''));
                }
                if (tuppleObject.attr("data-next-bid-amount")) {
                    listingObject.minBidAmount = Number(tuppleObject.attr("data-next-bid-amount").replace(/[\$,]/g, ''));
                    listingObject.nextBidAmount = Number(tuppleObject.attr("data-next-bid-amount").replace(/[\$,]/g, ''));
                }

                return listingObject;
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:getListingValues|" + e, null, 1);
            }
        },

        attachEvents: function () {
            var self = this;
            (function(){
                $("#ga-loader-mask").click(function () {
                    self.showAlertContainer();
                });
            })($);
        },

        addWindowResizer: function () {

            try {
                var self = this;
                window.addEventListener("resize", function () {
                    self.isMobile = $(window).width() < 768 ? true : false;
                    self.hideAlertContainer();
                });
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:addWindowResizer|" + e, null, 1);
            }
        },

        /**
        *   Anchor link opens SRP page with given criteria 
        *   Logs GA event with creteria details
        */
        
        openSaveSearchLink: function(e) {
            try {
                var saveSearchName = e.target && $(e.target).attr("data-search-name") || '',
                    saveSearchCriteriaId = e.target && $(e.target).attr("data-criteria-id") || '';

                GAUtils.eventTracking.trackevent("Global Alerts", "Global Alerts - Save seach alert link clicked",  this.userDetails+ " | visitorId:"+ this.visitorId + " | Search Name: " + saveSearchName + " | Search Criteria Id: " + saveSearchCriteriaId+ "| PageName:"+this.pageName);

            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:openSaveSearchLink|" + exception, null, 1);
            }
        },
        logUserActions: function(e){
            try {
                var label = $(e.target).attr("data-label") || $(e.target).parents('.carousel-control').attr('data-label') || '',
                    controlType = $(e.target).attr("data-name") || $(e.target).parents('.carousel-control').attr('data-name') || '';
                GAUtils.eventTracking.trackevent("Global Alerts", "Global Alerts - Marketing alerts - " + controlType+ " click",  this.userDetails+ " | visitorId:"+ this.visitorId + " | control:"+ controlType + " | label:" + label +  "| PageName:"+this.pageName);
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:logUserActions|" + error, null, 1);   
            }
        },
        showFeatureDetails: function(e) {
            try {
                var self = this,
                    index = $(e.target).attr('data-slide-index'),
                    content = saveListDrawerContent.globalAlerts.marketingAlerts || {},
                    feature;
                
                index = index ? Number(index) : -1;
                feature = (index > -1) ? self.marketingAlerts[index] : {};

                GAUtils.eventTracking.trackevent("Global Alerts", "Global Alerts - Marketing alerts - show more click",  this.userDetails+ " | visitorId:"+ this.visitorId +  "| PageName:"+this.pageName);
                
                if (self.marketingAlerts.length) {

                    $('.modal-popup-container').html(whatsNewModalTemplate({
                        alerts: self.marketingAlerts,
                        content: content
                    }));
                    $('.modal-popup-container .whats-new-modal').modal('show');
                    
                    if (self.isMobile) {
                        $('.modal-popup-container .whats-new-modal').on("hidden.bs.modal", function(){
                            $(".whats-new-modal").css("zIndex", "1050");
                            $(".modal-backdrop").css("zIndex", "1040");
                        });
                        $(".whats-new-modal").css("zIndex", "1051");
                        $(".modal-backdrop").css("zIndex", "1050");
                    }
                }

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:showFeatureDetails|" + error, null, 1);
            }
        },
        renderWhatsNewFeatures: function(options) {
            try {
                
                options = options || {};
                
                var contextPath = options.contextPath || '',
                    self = this,
                    content = saveListDrawerContent.globalAlerts.marketingAlerts || {};

                if (content.config.isEnabled !== 'Y') return; 

                // whatsNewTemplate
                $.ajax({
                    url: contextPath + '/portal/api/getMarketingAlerts',
                    type: 'GET',
                    success: function(response) {
                        response = response || {};
                        self.marketingAlerts = response.data && response.data.slideList || [];

                        if (response.status === 'SUCCESS' && self.marketingAlerts.length) {
                            $(".ga-whats-new-container").html(whatsNewTemplate({
                                alerts: self.marketingAlerts,
                                content: content,
                                enableControls: (self.marketingAlerts.length > 1) ? true : false 
                            }));
                        }
                    },
                    error: function(xhr, status, error) {
                        console.log('ERROR::' , status);
                    }
                });

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:renderWhatsNewFeatures|" + error, null, 1);
            }
        },

        initPopOver : function(){
            try{
                var isMobile = $(window).width() < 768 ? true : false;
                if(isMobile) {
                    $('.popOverInfo').popover({
                        trigger: 'hover',
                        html: true,
                        placement: 'bottom'
                    });
                } else {
                    $('.popOverInfo').popover({
                        trigger: 'manual',
                        html: true,
                        placement: 'bottom'
                    });
                    
                    $('.popOverInfo').on("click mouseover", function() {
                        $('.popOverInfo').not(this).popover('hide');
                        $(this).popover('show');
                    });

                    $(window).on('click touchstart',function(e) {
                        $('.popOverInfo').popover('hide');
                    });
                }


            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:initPopOver|" + e, null, 1);
            }
        },

        checkEnableAutoBid : function(event){
            try{
                var messageId = $(event.target).attr('data-message');
                if($("#b_autobid_amount-"+messageId).val() === '') {
                    $("#b_autobid_amount-"+messageId).val('$');
                }
                $('#b_autobid_amount-'+messageId).removeClass('error');
                if ($('#enable-auto-bid-'+messageId).is(':checked')) {
                    $("#autoBidSectionGA-"+messageId).slideDown('slow');
                    GAUtils.eventTracking.trackevent("Global Alerts", 'Enable Auto-bid: True', this.userDetails+ " | visitorId:"+ this.visitorId +  "| PageName:"+this.pageName); 
                }else{
                    $("#autoBidSectionGA-"+messageId).slideUp('slow');
                    GAUtils.eventTracking.trackevent("Global Alerts", 'Enable Auto-bid: False', this.userDetails+ " | visitorId:"+ this.visitorId +  "| PageName:"+this.pageName);
                }
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:checkEnableAutoBid|" + e, null, 1);
            }
        },

        checkAutoBidAmount : function(event){
            try{
                var self = this,
                    errorElement = $(event.target).attr("rel"),
                    amount = $.trim($(event.target).val()),
                    listingId = $(event.target).attr('data-listingId'),
                    messageId = $(event.target).attr('data-message'),
                    propState = $(event.target).attr('data-state'),
                    bidAmount = $.trim($('.bid-val-'+messageId).val()),
                    previousValue = $(event.target).attr('data-amt') || 0,
                    maxPossibleAmount = parseInt($('.' + listingId).attr("data-max-bid")),
                    minPossibleAmount = parseInt($('.' + listingId).attr("data-min-bid")),
                    minIncrement = parseInt($('.' + listingId).attr("data-bid-min-inc")),
                    listingObject = self.getListingValues({messageId: messageId}),
                    options = {};
                    $(event.target).removeClass('error');
                if(bidAmount != ''){
                    if(bidAmount.indexOf('$') != -1){
                        bidAmount = parseInt(bidAmount.split('$')[1].replace(/,/g , ""));
                    }else{
                        bidAmount = parseInt(bidAmount.replace(/,/g , ""));
                    }
                }
                if(amount == ''){
                    $('.' + errorElement).html('Please enter Auto bid limit');
                    $(event.target).addClass('error');
                    $('#b-autobid-bp-text-'+messageId).html("-");
                    $('#b-autobid-total-text-'+messageId).html("-");
                    $('#b_autobid_amount-'+messageId).val('');
                    return;
                }
                if(amount == '$'){
                    $('.' + errorElement).html('Please enter Auto bid limit');
                    $(event.target).addClass('error');
                    $('#b-autobid-bp-text-'+messageId).html("-");
                    $('#b-autobid-total-text-'+messageId).html("-");
                    return;
                }
                if(amount.indexOf('$') != -1){
                    amount = parseInt(amount.split('$')[1].replace(/,|%/g , ""));
                }else{
                    amount = parseInt(amount.replace(/,/g , ""));
                }
                $('#b_autobid_amount-'+messageId).val('$'+self.formatCurrencyLabel(amount));
                if(amount <= bidAmount){
                    $('.' + errorElement).html('Limit must exceed original bid amount');
                    $(event.target).addClass('error');
                }else if( amount > maxPossibleAmount){
                    $('.' + errorElement).html('Oops! Max bid amount is $'+ self.formatCurrencyLabel(maxPossibleAmount));
                    $(event.target).addClass('error');
                }else if(listingObject.isFcOnline == 'Y' &&  listingObject.reservePrice !='' && listingObject.reservePrice == amount){
                    var errorCodeReserverPrc = "RESERVE_PRICE_VAL";
                    var nextPriceFormat = self.formatMoney(amount + listingObject.bidIncrementPostReserve);
                    var errorMessageReservePrc = saveListDrawerContent.placeBidContent.placeBidError[errorCodeReserverPrc] || '';
                    errorMessageReservePrc = errorMessageReservePrc.replace('###' + errorCodeReserverPrc + '###',nextPriceFormat);
                    $('.' + errorElement).html(errorMessageReservePrc);
                    $(event.target).addClass('error');
                } else if((listingObject.isActiveBidRefinementEnabled === 'Y')&& (amount > parseInt(listingObject.lowerThresholdAmount) && amount < parseInt(listingObject.upperThresholdAmount))){
                    if(amount > (parseInt(listingObject.lowerThresholdAmount) + (parseInt(listingObject.minBidAmount) % parseInt(listingObject.lowerThresholdIncrementMultiplier)))){
                        var nextBidAmount = (parseInt(listingObject.lowerThresholdAmount) + (parseInt(listingObject.minBidAmount) % parseInt(listingObject.lowerThresholdIncrementMultiplier)));
                        // $('.' + errorElement).html('Oops! Max bid amount is $'+ self.formatCurrencyLabel(nextBidAmount));
                        var autoBidErrorCode = "MAX_AUTOBID_AMOUNT_ERROR";
                        var autoBidErrorMsg = saveListDrawerContent.placeBidContent.placeBidError[autoBidErrorCode] || '';
                        $('.' + errorElement).html((autoBidErrorMsg).replace('###MAX_AUTOBID_AMOUNT_ERROR###',self.formatCurrencyLabel(nextBidAmount)));
                        $(event.target).addClass('error');
                    } else if((amount - parseInt(minPossibleAmount)) % listingObject.lowerThresholdIncrementMultiplier != 0){
                        var errorCodeLowerTresh = "LOWER_TRESH_ERROR";
                        var errorMessageLower = saveListDrawerContent.placeBidContent.placeBidError[errorCodeLowerTresh] || '';
                        $('.' + errorElement).html((errorMessageLower).replace('###LOWER_TRESH_INC###',listingObject.lowerThresholdIncrementMultiplier));
                        $(event.target).addClass('error');
                    }
                } else if((listingObject.isActiveBidRefinementEnabled === 'Y')&& (amount > parseInt(listingObject.lowerThresholdAmount) && ((parseInt(listingObject.currentHighestBid) + parseInt(listingObject.lowerThresholdIncrementMultiplier)) < parseInt(listingObject.upperThresholdAmount)))){
                    var nextBidAmount = (parseInt(listingObject.lowerThresholdAmount) + (parseInt(listingObject.minBidAmount) % parseInt(listingObject.lowerThresholdIncrementMultiplier)));
                    // $('.' + errorElement).html('Oops! Max bid amount is $'+ self.formatCurrencyLabel(nextBidAmount));
                    var autoBidErrorCode = "MAX_AUTOBID_AMOUNT_ERROR";
                    var autoBidErrorMsg = saveListDrawerContent.placeBidContent.placeBidError[autoBidErrorCode] || '';
                    $('.' + errorElement).html((autoBidErrorMsg).replace('###MAX_AUTOBID_AMOUNT_ERROR###',self.formatCurrencyLabel(nextBidAmount)));
                    $(event.target).addClass('error');
                } else if((listingObject.isActiveBidRefinementEnabled === 'Y')&& (amount >= parseInt(listingObject.upperThresholdAmount))){
                    if((amount - parseInt( minPossibleAmount)) % listingObject.upperThresholdIncrementMultiplier != 0){
                        var errorCodeUpperTresh = "UPPER_TRESH_ERROR";
                        var errorMessageUpper = saveListDrawerContent.placeBidContent.placeBidError[errorCodeUpperTresh] || '';
                        $('.' + errorElement).html((errorMessageUpper).replace('###UPPER_TRESH_INC###',listingObject.upperThresholdIncrementMultiplier));
                        $(event.target).addClass('error');
                    }
                } else if ( (amount - minPossibleAmount) % minIncrement != 0 && propState != 'KY'){
                        $('.' + errorElement).html('Please use $' + self.formatCurrencyLabel(minIncrement) +' increments');
                        $(event.target).addClass('error');
                }else{
                    $(event.target).removeClass("error");
                    if($('#enable-auto-bid-'+messageId).is(':checked')){
                        bidWizardModel.attributes.autoBidAmountDetail.bidAmount = amount;
                    }
                    previousValue = previousValue && previousValue.replace("$", "").replaceAll(",","") || 0;
                    if(amount == previousValue){
                        if(!($('#b-autobid-total-text-'+messageId).text() == '-')){
                             return;
                        }
                    } 

                    options.amount = amount;
                    options.listingId = listingId;
                    options.messageId = messageId;
                    options.bidType = "autobid";
                    self.getBuyersPremiumForAutoBid(options);       
                }
                if($(event.target).hasClass("error")){
                    $('#b-autobid-bp-text-'+messageId).html("-");
                    $('#b-autobid-total-text-'+messageId).html("-");
                }
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:checkAutoBidAmount|" + e, null, 1);
            }
        },

        getBuyersPremiumForAutoBid: function(options) {
            try{
                options = options || {}

                var self = this,
                    url = contextPathPortal + '/v2.0/getBidAmountDetailWithoutEMD/'+options.listingId+'/' + options.amount
                    options.viewObject= this;
                
                Utils.fetch({
                    type: "GET",
                    url: url,
                    viewObject: self,
                    options: options,
                    callback: self.getBuyersPremiumCallback
                });

            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:getBuyersPremiumForAutoBid|" + e, null, 1);
            }
        },

        getBuyersPremiumCallback: function(options) {
            try {
                options = options || {};
                var self= options.options.viewObject || this,
                    response = options.response || {},
                    inputData = options.options.options || {}, 
                    apiStatus = options.status || 'ERROR',
                    status = response.status || "FAILURE",
                    data = response.data || {};
                var bidType = inputData.bidType;
                var messageId = inputData.messageId;
                if (apiStatus === "SUCCESS"){
                    self.hideLoader();
                    if(status === "SUCCESS"){
                        $('#b-'+bidType+'-bp-text-'+messageId).html(data.buyerPremiumFormatted);
                        if (data.premiumPaidBy == 'SELLER') {
                            $('#b-'+bidType+'-total-text-'+messageId).html(data.bidAmountFormatted);
                        } else {
                            $('#b-'+bidType+'-total-text-'+messageId).html(data.totalDueFormatted);
                        }
                        if(bidType === 'autobid'){
                            if(data.buyerPremiumPercentage !== null && data.buyerPremiumPercentage !== '' && data.buyerPremiumPercentage !== 0 && data.buyerPremiumPercentage !== '0'){
                                $('#autoBidBPPerc-'+messageId).html(' (' + data.buyerPremiumPercentage + '%)');
                            }else{
                                $('#autoBidBPPerc-'+messageId).html('');
                            }
                            $("#b_autobid_amount-"+messageId).attr('data-amt',data.bidAmount);
                        }
                    }else if(status === "SESSION_EXPIRED"){
                        loginRegisterView.openLoginPopup({
                            callback: self.getBuyersPremiumLoginCallback,
                            viewObject: {
                                viewObject: self,
                                inputData: inputData
                            },
                            statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessage
                        });
                    }    
                } 
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:getBuyersPremiumCallback|" + error, null, 1);
            }
        },

        formatCurrencyLabel : function(num){
            num = num.toString().replace(/\$|\,/g,'');
            if(isNaN(num)){
                num = "0";
                return num;
            }else{
                for (var i = 0; i < Math.floor((num.length-(1+i))/3); i++){
                    num = num.substring(0,num.length-(4*i+3))+','+num.substring(num.length-(4*i+3));
                }
                return (num);
            }
        },

        checkBidKeyPress : function(event){
            try{
                var fieldId = '#'+event.target.id;
                if(validator.amountField(event , fieldId)){
                    return true;
                }else{
                    return false;
                }
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:checkBidKeyPress|" + e, null, 1);
            }
        },

        render: function () {
            
            try {
                
                var globalAlertsCmsNode = saveListDrawerContent.globalAlerts || {},
                    loginRegister = saveListDrawerContent.globalAlerts || {},
                    phoneConsent = saveListDrawerContent.phoneConsentSection.consentTerms || null,
                    phoneConsent = phoneConsent.replace('##PATH##', contextPath),
                    elementClass = ($(window).width()<768) ? ".gobal-alerts-drawer-popup" : ".gobal-alerts-drawer-slider";

                if(newSkinning){
                    globalAlertsCmsNode.allCaughtUpImage  = springRequest+"hubzu-web/images/global-alert-error.png";
                    $(this.el).find(elementClass).html(alertCounterVersion1TemplateNewSkin({
                        globalAlertsCmsNode: globalAlertsCmsNode, phoneConsent: phoneConsent
                    }));
                }else{
                    $(this.el).find(elementClass).html(alertCounterVersion1Template({
                        globalAlertsCmsNode: globalAlertsCmsNode, phoneConsent: phoneConsent
                    }));    
                }
                    
                

                // in case browser update/maintainance banner added, 
                // add new class which will update top position of alert drawer 
                if($("#browserVersionUpdate").is(':visible')){
                    $("#globalAlertsContainer .tuple-container").addClass('bannerAdded');
                }

                // render whats new alerts 
                this.renderWhatsNewFeatures({
                    contextPath: contextPath
                });

                return this;
            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:alertsView.js|function:render|" + exception, null, 1);
            }
        
        }

    });

    return AlertsView;
});
